import React, { PureComponent } from 'react';
import types from 'prop-types';
import { styled, StyleSheet, keyframes } from '@rexlabs/styling';

const spinnerAnimation = keyframes({
  '0%, 60%': {
    opacity: 0.25
  },
  '80%': {
    opacity: 1
  },
  '100%': {
    opacity: 0.25
  }
});

const defaultStyles = StyleSheet({
  container: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    height: '15px',
    opacity: '70%',
    color: 'white',
    width: '65px'
  },

  centered: {
    display: 'flex',
    margin: '0 auto'
  },

  small: {
    width: '42px',
    height: '10px'
  },

  extraSmall: {
    width: '32px',
    height: '6px'
  },

  dot: {
    width: '15px',
    height: '15px',
    display: 'block',
    borderRadius: '20px',
    background: 'currentColor',
    opacity: 0.25,
    animationName: spinnerAnimation
  },

  dotDark: {
    background: 'rgb(66,66,66)'
  },

  dotSmall: {
    width: '10px',
    height: '10px'
  },

  dotExtraSmall: {
    width: '6px',
    height: '6px'
  },

  one: {
    animationDuration: '1.4s',
    animationTimingFunction: 'linear',
    animationDelay: '0s',
    animationIterationCount: 'infinite'
  },

  two: {
    left: 0,
    animationDuration: '1.4s',
    animationTimingFunction: 'linear',
    animationDelay: '.2s',
    animationIterationCount: 'infinite'
  },

  three: {
    animationDuration: '1.4s',
    animationTimingFunction: 'linear',
    animationDelay: '.4s',
    animationIterationCount: 'infinite'
  },

  classic: {
    animationName: 'spinnerAnimation'
  }
});

@styled(defaultStyles)
class Spinner extends PureComponent {
  static propTypes = {
    small: types.bool,
    extraSmall: types.bool,
    centered: types.bool,
    classic: types.bool
  };

  render() {
    const {
      styles: s,
      small,
      extraSmall,
      dark,
      centered,
      classic,
      ...props
    } = this.props;

    return (
      <div {...s.with('container', { small, extraSmall, centered })(props)}>
        <div
          {...s('dot', 'one', {
            dotSmall: small,
            dotExtraSmall: extraSmall,
            dotDark: dark,
            classic
          })}
        />
        <div
          {...s('dot', 'two', {
            dotSmall: small,
            dotExtraSmall: extraSmall,
            dotDark: dark,
            classic
          })}
        />
        <div
          {...s('dot', 'three', {
            dotSmall: small,
            dotExtraSmall: extraSmall,
            dotDark: dark,
            classic
          })}
        />
      </div>
    );
  }
}

export default Spinner;
