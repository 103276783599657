import React, { PureComponent } from 'react';

import AuthLayout from 'view/layouts/auth';

import WrapperLayout from 'shared/layouts/wrapper';

class AuthApp extends PureComponent {
  render() {
    return (
      <WrapperLayout>
        <AuthLayout {...this.props} />
      </WrapperLayout>
    );
  }
}

export default AuthApp;
