import React from 'react';
import Text from '@rexlabs/text';
import { map } from 'shared/utils/text';

export default ({ regular, undecorated, grey, ...props }) => (
  <Text
    is='a'
    {...props}
    type={map({
      LINK: true,
      UNDECORATED: undecorated,
      REGULAR: regular,
      GREY: grey
    })}
  />
);
