import _ from 'lodash';

export function parseDirtyBoolean(maybeBoolean) {
  if (_.isBoolean(maybeBoolean)) return maybeBoolean;
  else if (maybeBoolean === '0') return false;
  else return !!maybeBoolean;
}

export function parseUserPrivilegesResponse(response) {
  return _.reduce(
    response,
    function (result, item) {
      try {
        const privilegeKey = `${item.group.friendly_id}.${item.privilege}`;

        // Don't override an existing privilege if the existing one
        // is better than the current one
        if (result[privilegeKey]) {
          if (item.condition && !result[privilegeKey].condition) {
            return result;
          }
          if (
            item.time_limit_seconds &&
            !result[privilegeKey].time_limit_seconds
          ) {
            return result;
          }
        }

        result[privilegeKey] = item;

        return result;
      } catch (error) {
        console.error('Failed to load privilege:', item);
        throw error;
      }
    },
    {}
  );
}
