/*
|-------------------------------------------------------------------------------
| App Styling Constants
|-------------------------------------------------------------------------------
|
| The theme of an app encompasses:
|  1. Design Rules
|  2. Style Utilities
|
| These are here so we can apply discipline to styling our app, and avoid
| spaghetti styling code.
|
*/

import { insertCss, reset, keyframes } from '@rexlabs/styling';

export const COLORS = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  BLUE: '#5FBEEC',
  BLUE_DARK: '#213A6A',
  GREY: '#A0ABBF',
  DARK_GREY: '#4B4F55',
  DARKER_GREY: '#4A4A4A',
  SLATE_DARK: '#79818D',
  SAND: '#F2EEE6',
  BLACK_15_OPACITY: 'rgba(0, 0, 0, 0.15)',

  PRIMARY: {
    RED: '#D0021B'
  },

  LOGIN: {
    POCKET_BLUE: '#029FBB',
    BUTTON_PRIMARY: '#5FBEEC',
    BUTTON_HOVER: '#5FBEEC',
    BUTTON_FOCUS: 'rgba(95,190,236,0.40)',
    STATES: {
      IDLE: '#B4BBC7',
      HOVER: '#9AA4B4',
      ACTIVE: '#3F4A55'
    }
  },

  BRANDING: {
    REX_BLUE: '#213A6A',
    SKY_BLUE: '#5FBEEC',
    SUNSHINE_YELLOW: '#FFC62F',
    CORAL_PINK: '#F7A7A3',
    DARK_BLUE: '#1D1934',
    WARM_GREY: '#4B4F55',
    LIGHT_GREY: '#F2EEE6'
  }
};
export const BREAKPOINTS = {
  DESKTOP: '@media(min-width: 1024px)',
  MOBILE: '@media(max-width: 800px)',
  TABLET: '@media(max-width: 1023px)',
  SMALL_MOBILE: '@media(max-width: 500px)'
};

export const PADDINGS = {
  '3XS': '4px',
  XXS: '5px',
  XS: '10px',
  S: '15px',
  M: '20px',
  L: '25px',
  XL: '30px',
  XXL: '35px',
  '3XL': '40px'
};

export const BORDER_RADIUS = {
  BUTTONS: '2px'
};

export const FONT = {
  SIZES: {
    SMALL: '12px',
    TAG: '13px',
    REGULAR: '14px',
    LARGE: '16px',
    XL: '18px',

    NAV_MENU: {
      TIER_ONE: '15px',
      TIER_TWO: '14px'
    }
  },
  LINE_HEIGHTS: {
    NAV_MENU: '22px'
  },
  WEIGHTS: {
    LIGHT: '100',
    REGULAR: '400',
    MEDIUM: '500',
    SEMIBOLD: '600',
    BOLD: '900'
  },
  SPACING: {
    REGULAR: '0.35px',
    LARGE: '0.5px'
  },
  TRANSFORM: {
    UPPERCASE: 'uppercase'
  },
  FAMILY: {
    PROXIMA_NOVA: 'proxima-nova, lato, arial, serif'
  }
};

export const TEXTS = {
  HEADING: {
    lineHeight: '28px',
    fontSize: '27px',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: COLORS.DARK_GREY
  },

  HEADING_1: {
    lineHeight: '40px',
    fontSize: '36px',
    fontWeight: FONT.WEIGHTS.BOLD
  },

  HEADING_2: {
    lineHeight: '20px',
    fontSize: '17px'
  },

  HEADING_3: {
    lineHeight: '14px',
    fontSize: '12px'
  },

  HEADING_4: {
    lineHeight: '14px',
    fontSize: '12px'
  },

  BODY: {
    lineHeight: '19px',
    fontSize: '14px',
    fontWeight: FONT.WEIGHTS.REGULAR
  },

  LOGIN: {
    AVATAR: {
      color: COLORS.WHITE,
      fontSize: '16px',
      lineHeight: '28px',
      fontWeight: FONT.WEIGHTS.MEDIUM
    }
  },

  FIELD: {
    LABEL: {
      color: COLORS.GREY,
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: FONT.WEIGHTS.REGULAR
    },
    LABEL_DARK: {
      color: COLORS.DARK_GREY,
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: FONT.WEIGHTS.REGULAR
    },
    ERROR: {
      color: COLORS.PRIMARY.RED,
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: FONT.WEIGHTS.REGULAR
    }
  },

  INPUT: {
    CONTENT: {
      color: COLORS.DARK_GREY,
      lineHeight: '19px',
      fontSize: '16px',
      fontWeight: FONT.WEIGHTS.REGULAR
    },
    PLACEHOLDER: {
      fontSize: '16px',
      fontWeight: FONT.WEIGHTS.REGULAR,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      fontStyle: 'normal',
      color: '#B4B1A9'
    },
    SELECT_OPTION: {
      lineHeight: '17px',
      fontSize: '16px',
      fontWeight: FONT.WEIGHTS.REGULAR,
      color: COLORS.SLATE_DARK
    }
  },

  OPTION_LABEL: {
    lineHeight: '17px',
    fontSize: '16px',
    fontWeight: 400,
    color: COLORS.SLATE_DARK
  },

  OPTION_DETAIL: {
    lineHeight: '18px',
    fontSize: '13px',
    fontWeight: 300,
    color: '#424242'
  },

  LINK: {
    lineHeight: '17px',
    fontSize: '14px',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: COLORS.DARK_GREY,
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  UNDECORATED: {
    textDecoration: 'none'
  },

  NORMAL: {
    fontStyle: 'normal'
  },

  ITALIC: {
    fontStyle: 'italic'
  },

  REGULAR: {
    fontWeight: FONT.WEIGHTS.REGULAR
  },

  MEDIUM: {
    fontWeight: FONT.WEIGHTS.MEDIUM
  },

  SEMIBOLD: {
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  },

  BOLD: {
    fontWeight: FONT.WEIGHTS.BOLD
  },

  SMALL: {
    fontSize: '12px'
  },

  LARGE: {
    fontSize: '16px'
  },

  WHITE: {
    color: COLORS.WHITE
  },

  BLACK: {
    color: COLORS.BLACK
  },

  GREY: {
    color: COLORS.DARK_GREY
  },

  DARK_GREY: {
    color: COLORS.GREY
  },

  DARKER_GREY: {
    color: COLORS.DARKER_GREY
  },

  RED: {
    color: COLORS.PRIMARY.RED
  }
};

export const BORDERS = {};
export const EASINGS = {};
export const SHADOWS = {};

export const UTILS = {
  TRUNCATE: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export const KEYFRAMES = {
  FADE_IN: keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  }),
  FADE_OUT: keyframes({
    '0%': { opacity: 1 },
    '100%': { opacity: 0 }
  })
};

export function initTheme() {
  reset();
  insertCss(`
    html {
      font-size: 62.5% !important;
      height: 100%;
      box-sizing: border-box;
      font-family: "proxima-nova", 'Proxima Nova', helvetica, arial, sans-serif;
      -webkit-font-smoothing: antialiased;
    }
    
    
    html, body {
      overflow-x: hidden;
    }

    body {
      min-height: 100%;
      display: flex;
    }

    button, input, textarea, select {
      font: inherit;
    }

    *, *:before, *:after {
      box-sizing: inherit;
    }

    a {
      text-decoration: none;
      color: initial;
    }

    #app {
      overflow-x: hidden;
      flex: 1;
    }
  `);
}
