import React from 'react';
import { DefaultButton } from 'shared/components/button';
import Spinner from 'shared/components/spinner';

function SmallSpinner(props) {
  return <Spinner {...props} small />;
}

export default function LoadingButton(props) {
  return <DefaultButton {...props} Loading={SmallSpinner} />;
}
