// TODO: this class will be abstracted to Vivid
let handlers = [];

const TYPES = {
  IDENTIFY: 'IDENTIFY',
  CUSTOM: 'CUSTOM',
  INFORMATION: 'INFORMATION',
  TRACK: 'TRACK',
  ERROR: 'ERROR',
  PERFORMANCE: 'PERFORMANCE',
  NAVIGATION: 'NAVIGATION'
};

function dispatchEvent(type, event) {
  const action = {
    type: `VIVID_ANALYTICS/${type}`,
    payload: event
  };
  try {
    handlers.forEach((handler) => {
      typeof handler === 'function' && handler(action);
    });
  } catch (e) {
    console.error('Uncaught error inside analytics handlers', e);
  }
}

const Analytics = {
  setHandlers: (maybeArray) => {
    if (Array.isArray(maybeArray)) {
      handlers = maybeArray;
    } else if (typeof maybeArray === 'function') {
      handlers = [maybeArray];
    }
  },

  identify: (event) => {
    dispatchEvent(TYPES.IDENTIFY, event);
  },

  custom: (event) => {
    dispatchEvent(TYPES.CUSTOM, event);
  },

  error: (event) => {
    dispatchEvent(TYPES.ERROR, event);
  },

  performance: (event) => {
    dispatchEvent(TYPES.PERFORMANCE, event);
  },

  navigation: (event) => {
    dispatchEvent(TYPES.NAVIGATION, event);
  },

  information: (event) => {
    dispatchEvent(TYPES.INFORMATION, event);
  },

  track: (event) => {
    dispatchEvent(TYPES.TRACK, event);
  }
};

export { TYPES };
export default Analytics;
