import React, { Component } from 'react';
import types from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'theme/index';

export const ICONS = {
  /** NOTE: These icons are serving as an intermediary until replaced with the new icons below. */
  APP_SEARCH: {
    CONTACT: require('assets/icons/nav/contact.svg'),
    BUILDING: require('assets/icons/app-search/building.svg'),
    PROJECT: require('assets/icons/app-search/building.svg'),
    PROJECT_STAGE: require('assets/icons/app-search/building.svg'),
    INVOICE: require('assets/icons/app-search/invoice.svg'),
    LISTING: require('assets/icons/nav/listing.svg'),
    CURRENT_LISTING: require('assets/icons/nav/listing.svg'),
    ARCHIVED_LISTING: require('assets/icons/nav/listing.svg'),
    PROPERTY: require('assets/icons/nav/property.svg'),
    MARKET_LEAD: require('assets/icons/app-search/market-lead.svg'),
    SEARCH: require('assets/icons/app-search/search.svg'),
    RECENT: require('assets/icons/app-search/recent.svg'),
    ARROW: require('assets/icons/app-search/arrow.svg'),
    CROSS: require('assets/icons/nav/close.svg'),
    OWNER: require('assets/icons/app-search/rex-my-record.svg'),
    LOCK: require('assets/icons/app-search/rex-lock.svg'),
    CAMPAIGN: require('assets/icons/campaign_trimmed.svg')
  },

  SELECT: {
    PROPERTIES: require('assets/icons/property.svg'),
    LISTINGS: require('assets/icons/listing.svg'),
    VALUE: {
      TOWN_CITY: require('assets/icons/size-14-14/town_city.svg'),
      STREET: require('assets/icons/size-14-14/street.svg'),
      POSTCODE: require('assets/icons/size-14-14/postcode.svg'),
      POSTCODE_AREA: require('assets/icons/size-14-14/town_city.svg'),
      POSTCODE_DISTRICT_SECTOR: require('assets/icons/size-14-14/postcode_district_sector.svg')
    },
    OPTION: {
      TOWN_CITY: require('assets/icons/size-19-19/town_city.svg'),
      STREET: require('assets/icons/size-19-19/street.svg'),
      POSTCODE: require('assets/icons/size-19-19/postcode.svg'),
      POSTCODE_AREA: require('assets/icons/size-19-19/town_city.svg'),
      POSTCODE_DISTRICT_SECTOR: require('assets/icons/size-19-19/postcode_district_sector.svg')
    }
  },

  PAGINATION: {
    CHEVRON_ARROW_LEFT: require('assets/icons/pagination/chevron-arrow-left.svg'),
    ARROW_LEFT: require('assets/icons/pagination/arrow-left.svg')
  },

  /** Use these and below */
  HAMBURGER: require('assets/icons/nav/hamburger.svg'),
  HAMBURGER_18: require('assets/icons/nav/hamburger-18.svg'),
  REXFEST_TICKET: require('assets/icons/nav/new/rexfest-ticket.svg'),
  HOME: require('assets/icons/nav/new/rex-home.svg'),
  LISTINGS: require('assets/icons/nav/new/listings.svg'),
  LISTINGS_12: require('assets/icons/listings-12.svg'),
  PROPERTIES: require('assets/icons/nav/new/properties.svg'),
  PROPERTIES_12: require('assets/icons/properties-12.svg'),
  BUILDING: require('assets/icons/nav/new/buildings.svg'),
  CONTACTS: require('assets/icons/nav/new/contacts.svg'),
  CONTACTS_12: require('assets/icons/contacts-12.svg'),
  CALENDAR_12: require('assets/icons/calendar-12.svg'),
  INSPECTION_12: require('assets/icons/inspection-12.svg'),
  OAB_12: require('assets/icons/oab-12.svg'),
  APPRAISAL_13: require('assets/icons/appraisal-13.svg'),

  FINANCE: require('assets/icons/nav/new/financials.svg'),
  FINANCIALS_POUND_12: require('assets/icons/financials-pound-12.svg'),
  FINANCIALS_DOLLAR_12: require('assets/icons/financials-dollar-12.svg'),
  DOCUMENT_POUND_12: require('assets/icons/document-pound-12.svg'),
  DOCUMENT_DOLLAR_12: require('assets/icons/document-dollar-12.svg'),
  DOCUMENT_COG_12: require('assets/icons/document-cog-12.svg'),
  DOCUMENT_12: require('assets/icons/document-12.svg'),
  CURRENCY_POUND_12: require('assets/icons/currency-pound-12.svg'),
  CURRENCY_DOLLAR_12: require('assets/icons/currency-dollar-12.svg'),
  CIRCLE_POUND_12: require('assets/icons/circle-pound-12.svg'),
  CIRCLE_DOLLAR_12: require('assets/icons/circle-dollar-12.svg'),
  INVOICE: require('assets/icons/nav/new/invoices.svg'),
  INVOICE_12: require('assets/icons/invoice-12.svg'),
  OTHER: require('assets/icons/nav/new/other-lists.svg'),
  SETTINGS: require('assets/icons/nav/new/settings.svg'),
  ADMIN: require('assets/icons/nav/new/admin.svg'),
  CLOSE: require('assets/icons/nav/close.svg'),
  BACK: require('assets/icons/nav/close.svg'),
  DEALS: require('assets/icons/nav/new/deals.svg'),
  LEADS: require('assets/icons/nav/new/inbox.svg'),
  LEADS_12: require('assets/icons/inbox-12.svg'),
  REX_UNIVERSITY: require('assets/icons/nav/new/rex-university.svg'),
  LOGOUT: require('assets/icons/nav/logout.svg'),
  RELOAD: require('assets/icons/nav/reload.svg'),
  ANNOUNCEMENTS: require('assets/icons/nav/announcement.svg'),
  ADD: require('assets/icons/nav/add.svg'),
  COMPANY: require('assets/icons/nav/new/businesses.svg'),
  PHONE: require('assets/icons/nav/new/phone.svg'),
  EMAIL: require('assets/icons/nav/email.svg'),
  EMAIL_STRIKETHROUGH: require('assets/icons/email-strikethrough.svg'),
  LETTER: require('assets/icons/nav/letter.svg'),
  SMS: require('assets/icons/nav/sms.svg'),
  NOTE: require('assets/icons/nav/note.svg'),
  REMINDER: require('assets/icons/nav/reminder-2.svg'),
  FEEDBACK: require('assets/icons/nav/feedback-1.svg'),
  HELP: require('assets/icons/nav/help.svg'),
  HELP_ALT: require('assets/icons/help-2.svg'),
  APPS: require('assets/icons/nav/new/applications.svg'),
  SEARCH: require('assets/icons/nav/search.svg'),
  SEARCH_12: require('assets/icons/search-12.svg'),
  PRESENT: require('assets/icons/nav/present.svg'),
  TICK: require('assets/icons/tick.svg'),
  EMPTY: require('assets/icons/empty.svg'),
  SELECTED: require('assets/icons/selected.svg'),
  CHEVRON: require('assets/icons/nav/chevron.svg'),
  CHEVRON_RIGHT: require('assets/icons/chevron-large-right.svg'),
  CHEVRON_RIGHT_12: require('assets/icons/chevron-right-12.svg'),
  CHEVRON_DOWN_SMALL: require('assets/icons/chevron-small-down.svg'),
  UPLOAD_NEW: require('assets/icons/upload-new.svg'),
  PROJECT: require('assets/icons/nav/new/projects.svg'),
  PROJECTS_12: require('assets/icons/projects-12.svg'),
  PROJECT_STAGE: require('assets/icons/nav/new/projects.svg'),
  PROGRAMMING: require('assets/icons/programming.svg'),
  USER: require('assets/icons/user.svg'),

  // @deprecated
  DOCUMENT: require('assets/icons/document.svg'),
  VIDEO: require('assets/icons/video-library.svg'),
  SUPPORT: require('assets/icons/support-status.svg'),

  DONT_CALL: require('assets/icons/call-dont.svg'),
  DONT_MAIL: require('assets/icons/mail-dont.svg'),
  DONT_SMS: require('assets/icons/sms-dont.svg'),
  DONT_EMAIL: require('assets/icons/email-dont.svg'),

  WAND: require('assets/icons/wand.svg'),

  EDIT: require('assets/icons/edit.svg'),

  ADD_12: require('assets/icons/add-12.svg'),
  ADD_18: require('assets/icons/add-18.svg'),
  /**
   * @deprecated use ADD_18
   */
  ADD_MEDIUM: require('assets/icons/add-medium.svg'),
  ADD_MEDIUM_THICK: require('assets/icons/add-medium-thick.svg'),

  REMOVE: require('assets/icons/remove.svg'),

  DOWN: require('assets/icons/chevron-large-down.svg'),
  ARROW_BACK: require('assets/icons/back-arrow.svg'),
  ARROW_DOWN: require('assets/icons/arrow-down.svg'),
  CLOSE_LARGE: require('assets/icons/close-large.svg'),
  CLOSE_MEDIUM_THIN: require('assets/icons/close-medium-thin.svg'),
  CLOSE_MEDIUM: require('assets/icons/close-medium.svg'),
  CLOSE_SMALL: require('assets/icons/close-small.svg'),

  // Deprecated - this has the extra padding
  PADLOCK: require('assets/icons/lock.svg'),
  PADLOCK_18: require('assets/icons/padlock_18.svg'),

  EDIT_CONTENT: require('assets/icons/edit-content.svg'),
  CALENDAR: require('assets/icons/calendar.svg'),
  RECENT: require('assets/icons/recent.svg'),
  OVERLAY_BACK: require('assets/icons/overlay-back.svg'),

  WARNING: require('assets/icons/warning.svg'),
  WARNING_FILLED: require('assets/icons/warning-filled.svg'),
  WARNING_RED: require('assets/icons/warning-red.svg'),

  INFO: require('assets/icons/info.svg'),
  INFO_SMALL: require('assets/icons/info-12x12.svg'),
  MARKET_LEAD: require('assets/icons/nav/new/market-lead.svg'),

  CAMPAIGN: require('assets/icons/campaign.svg'),
  PERMALINK: require('assets/icons/permalink.svg'),
  DOWNLOAD: require('assets/icons/download.svg'),
  DOWNLOAD_SMALL: require('assets/icons/download-small.svg'),
  DRAG_HANDLE: require('assets/icons/drag-handle.svg'),

  // Workflow Widget Icons
  // TODO: Rename these to be more specific
  ACTIVE: require('assets/icons/active.svg'),
  CHECK: require('assets/icons/check.svg'),
  PAUSED: require('assets/icons/paused.svg'),
  FAILED: require('assets/icons/failed.svg'),

  // Group App Icons
  GROUP_APP_LOGO: require('assets/icons/group-app-logo.svg'),
  GROUP_APP_EMAIL: require('assets/icons/email.svg'),
  GROUP_APP_DOWNLOAD: require('assets/icons/download-new.svg'),

  // Login
  REX_LOGO: require('assets/icons/rex-logo.svg'),
  REX_PM_LOGO: require('assets/icons/rex-pm-logo.svg'),
  POCKET_LOGO: require('assets/icons/pocket.svg'),
  CLOUD: require('assets/icons/cloud.svg'),
  PAPER_PLANE: require('assets/icons/paper-plane.svg'),
  EYE: require('assets/icons/eye.svg'),
  EYE_CROSSED: require('assets/icons/eye-crossed.svg'),

  // Unsupported browser
  FIREFOX: require('assets/icons/firefox.svg'),
  SAFARI: require('assets/icons/safari.svg'),
  EDGE: require('assets/icons/edge.svg'),
  CHROME: require('assets/icons/chrome.svg'),

  // @deprecated
  TEMPLATE: require('assets/icons/template.svg'),
  REFERRAL: require('assets/icons/referral.svg'),

  FILTER: require('assets/icons/filter.svg'),
  COLUMN: require('assets/icons/column.svg'),
  ARROW_LEFT: require('assets/icons/arrow-left.svg'),
  ARROW_RIGHT: require('assets/icons/arrow-right.svg'),
  ACTION_MENU: require('assets/icons/action-menu.svg'),
  ARROW_UP: require('assets/icons/arrow-upwards.svg'),

  // Dashboard icons
  DASHBOARDS: {
    TRIANGLE_DOWN: require('assets/icons/dashboards/triangle-bottom.svg'),
    ARROW_FORWARD: require('assets/icons/dashboards/arrow-forward.svg'),
    ARROW_BACKWARD: require('assets/icons/dashboards/arrow-backward.svg'),
    NEGATIVE_CHANGE: require('assets/icons/dashboards/negative-change.svg'),
    POSITIVE_CHANGE: require('assets/icons/dashboards/positive-change.svg'),
    NO_CHANGE: require('assets/icons/dashboards/no-change.svg'),
    SECTION_SEPARATOR: require('assets/icons/dashboards/vertical-seperator.svg'),
    INFO_TOOLTIP: require('assets/icons/dashboards/i.svg'),
    CALENDAR: require('assets/icons/dashboards/calendar.svg'),
    FILTER: require('assets/icons/dashboards/filter.svg'),
    SIGNBOARD: require('assets/icons/dashboards/signboard.svg'),
    USERS: require('assets/icons/dashboards/users.svg'),
    COMPANY_LOCATION: require('assets/icons/dashboards/company-location.svg'),
    COLUMN: require('assets/icons/dashboards/column.svg')
  },

  // TEMP: Currently exporting as an svg isn't working correctly, so we are using a png in it's place until that can be sorted out
  ROSIE: require('assets/icons/rosie.png').default,

  // Added during the icon cleanup, moved from shell
  CRM_2_PRINT_LOGO: require('assets/icons/crm-2-print-logo.svg'),
  APPRAISAL: require('assets/icons/appraisal.svg'),

  ADD_DROPDOWN: require('assets/icons/add-dropdown.svg'),
  API: require('assets/icons/api.svg'),
  ARCHIVED: require('assets/icons/archived.svg'),
  ARROW_DOWN_HOLLOW: require('assets/icons/arrow-down-hollow.svg'),
  AUCTION: require('assets/icons/auction.svg'),
  AUTOMATIC_UPDATE: require('assets/icons/automatic-updates.svg'),
  BRIEFCASE: require('assets/icons/briefcase.svg'),
  CALENDAR_MENU: require('assets/icons/calendar-menu.svg'),
  CALENDAR_CALENDAR: require('assets/icons/calendar-calendar.svg'),
  CALENDAR_ALERTS: require('assets/icons/calendar-alerts.svg'),
  CALENDAR_DESCRIPTION: require('assets/icons/calendar-description.svg'),
  CALENDAR_ORGANISER: require('assets/icons/calendar-organiser.svg'),
  CALENDAR_GUESTS: require('assets/icons/calendar-guests.svg'),
  CALENDAR_LOCATION: require('assets/icons/calendar-location.svg'),
  CALENDAR_TIME: require('assets/icons/calendar-time.svg'),
  CALENDAR_TIME_ZONE: require('assets/icons/calendar-time-zone.svg'),
  CANCELLED: require('assets/icons/cancelled.svg'),
  CHAIN_LINK: require('assets/icons/chain-link.svg'),
  CIRCLE_FULL: require('assets/icons/system-search-full.svg'),
  CIRCLE_HOLLOW: require('assets/icons/circle-hollow.svg'),
  // @deprecated
  EXTERNAL_LINK_DEPRECATED: require('assets/icons/external-link-deprecated.svg'),
  EXTERNAL_LINK: require('assets/icons/external-link.svg'),
  E_MARKETING: require('assets/icons/e-marketing.svg'),
  GPS_LOGO: require('assets/icons/gps-logo.svg'),
  NEW_SEARCH: require('assets/icons/search.svg'),
  NO_UPLOAD: require('assets/icons/portal_uploads_disabled.svg'),
  NO_AUTOMATIC_UPDATE: require('assets/icons/auto_updates_disabled.svg'),
  NO_E_MARKETING: require('assets/icons/stocklist_emarketing_disabled.svg'),
  NO_API: require('assets/icons/api_disabled.svg'),
  OPEN_HOME: require('assets/icons/open-home.svg'),
  RECUR: require('assets/icons/recur.svg'),
  UPLOAD: require('assets/icons/upload.svg'),
  // @deprecated
  USER_GROUP: require('assets/icons/user-group.svg'),
  USER_GROUP_18: require('assets/icons/user-group-18.svg'),
  CATASTROPHIC_ERROR: require('assets/icons/catastrophic-error-illustration.svg'),
  RELATED_RECORD: require('assets/icons/related-record.svg'),

  REX_UNIVERSITY_LOADING: require('assets/icons/rex-university-loading-illustration.svg'),
  REX_UNIVERSITY_ERROR: require('assets/icons/rex-university-error-illustration.svg'),
  SCHEDULED_MESSAGE: require('assets/icons/scheduled-message.svg'),
  MESSAGE: require('assets/icons/message.svg'),
  SPARKLES: require('assets/icons/sparkles.svg'),
  SPARKLE: require('assets/icons/sparkle.svg'),
  GRAPH: require('assets/icons/dashboard-nav.svg'),
  GRAPH_SMALL: require('assets/icons/graph-bar.svg'),
  AGENT_VENDOR: require('assets/icons/reminder-agent-vendor.svg'),
  FOLLOW_UP: require('assets/icons/follow-up.svg'),
  ALERT: require('assets/icons/alert.svg'),
  ALERT_SMALL: require('assets/icons/alert-12x12.svg'),
  STREAM_NOTE: require('assets/icons/note-stream.svg'),
  MOBILE: require('assets/icons/note-mobile.svg'),
  MEETING_NOTE: require('assets/icons/note-meeting.svg'),
  LETTER_NOTE: require('assets/icons/note-letter.svg'),
  PHONE_SMALL: require('assets/icons/phone-small.svg'),
  CHAINS: require('assets/icons/chain.svg'),
  UNCONFIRMED_APPOINTMENT: require('assets/icons/unconfirmed.svg'),
  PINNED: require('assets/icons/pinned.svg'),
  PIN: require('assets/icons/pin.svg'),

  REPORT_NAV: require('assets/icons/nav/report.svg'),
  REPORT_15: require('assets/icons/report-15.svg'),
  REPORT_LARGE: require('assets/icons/report-large.svg'),
  VPA_12: require('assets/icons/contacts-12.svg'),
  KEY_12: require('assets/icons/key-12.svg'),
  ROWS_12: require('assets/icons/rows-12.svg'),

  STAR_15: require('assets/icons/star-15.svg'),
  STAR_18: require('assets/icons/star-18.svg'),
  STAR_OUTLINE_18: require('assets/icons/star-outlined-18.svg'),

  ROUND_CHECK: require('assets/icons/round-check.svg'),
  STEP_CHECK: require('assets/icons/step-check.svg'),

  PRIVACY: {
    CROSS: require('assets/icons/privacy/cross.svg')
  },

  MIN_MAX: require('assets/icons/min-max-input.svg'),
  SEND_EMAIL: require('assets/icons/send-email.svg'),
  REINZ_LOGO: require('assets/icons/reinz-logo.svg'),
  REFRESH: require('assets/icons/refresh.svg'),
  ROUND_CHECK_BIG: require('assets/icons/round-check-big.svg'),
  ROUND_CROSS_BIG: require('assets/icons/round-cross-big.svg'),

  TRACKS: {
    PHONE: require('assets/icons/nav/new/phone.svg'),
    EMAIL: require('assets/icons/nav/email.svg'),
    LETTER: require('assets/icons/reminder/letter.svg'),
    SMS: require('assets/icons/reminder/sms.svg'),
    TASK: require('assets/icons/reminder/task.svg'),
    INTERNAL_APPROVAL: require('assets/icons/reminder/internal-approval.svg'),
    REQUEST_SENT: require('assets/icons/reminder/request-sent.svg'),
    APPROVED_REMINDER: require('assets/icons/reminder/approved-reminder.svg'),
    REJECTED_REMINDER: require('assets/icons/reminder/rejected-reminder.svg'),
    TEMPLATE_BADGE: require('assets/icons/reminder/template-badge.svg')
  },

  CIRCLE_LOCK: require('assets/icons/circle-lock.svg')
};

/**
 * NOTE: These have been mapped to the BE status_id values so we can show the correct Icon.
 *
 * Here is the current list of values pulled from:
 *  SystemValues list "workflow_instance_statuses"
 *
 * ["id" => "running", "text" => "Running"],
 * ["id" => "awaiting_input", "text" => "Awaiting Input"],
 * ["id" => "finished", "text" => "Finished"],
 * ["id" => "error", "text" => "Error"]
 *
 * Extra (not in the SystemValues list): cancelled, active, completed
 */
export const mapWorkflowStatus = {
  active: 'ACTIVE',
  running: 'ACTIVE',
  finished: 'CHECK',
  completed: 'CHECK',
  awaiting_input: 'PAUSED',
  cancelled: 'FAILED',
  error: 'FAILED',
  failed: 'FAILED',
  terminated: 'FAILED'
};

const defaultStyles = StyleSheet({
  icon: {},

  iconControlledColor: {
    '& *:not([fill="none"])': {
      fill: 'currentColor'
    }
  },

  container: {},

  circle: {
    padding: '1rem',
    background: COLORS.COOL_GREY,
    borderRadius: '50%'
  }
});

@styled(defaultStyles)
class Icon extends Component {
  static propTypes = {
    type: types.func.isRequired,
    inCircle: types.bool,
    hasControlledColor: types.bool
  };

  static defaultProps = {
    hasControlledColor: true
  };

  render() {
    const {
      type: Type,
      inCircle,
      styles: s,
      width,
      height,
      hasControlledColor,
      'data-styletrace': dataStyletrace,
      'data-styletarget': dataStyletarget,
      'data-es-keys': dataEsKeys,
      ...rest
    } = this.props;

    return (
      <div
        {...{
          'data-styletrace': dataStyletrace,
          'data-styletarget': dataStyletarget,
          'data-es-keys': dataEsKeys,
          ...rest
        }}
        {...s.with('container', { circle: inCircle })(rest)}
      >
        {Type && (
          <Type
            {...s('icon', { iconControlledColor: hasControlledColor })}
            data-hello='world'
            width={width}
            height={height}
          />
        )}
      </div>
    );
  }
}

export default Icon;
