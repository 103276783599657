import React from 'react';
import Text from '@rexlabs/text';
import { map } from 'shared/utils/text';

export default ({ white, ...props }) => (
  <Text
    is='a'
    {...props}
    type={map({
      LINK: true,
      SMALL: true,
      UNDECORATED: true,
      REGULAR: true,
      WHITE: white
    })}
  />
);
