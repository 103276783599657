import React, { PureComponent } from 'react';
import Button from '@rexlabs/button';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { COLORS } from 'src/theme';
import _ from 'lodash';

const getStyles = _.memoize(
  (blue, fullWidth, hasIconLeft, hasIconRight) => ({
    Button: StyleSheet({
      container: {
        border: '1px solid',
        background: 'none',
        borderColor: blue ? COLORS.BLUE : COLORS.GREY_LIGHT,
        height: '36px',
        width: fullWidth ? '100%' : 'auto',
        paddingLeft: hasIconLeft ? '8px' : '12px',
        paddingRight: hasIconRight ? '8px' : '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: '"proxima-nova", helvetica, arial, sans-serif'
      },

      content: {
        color: blue ? COLORS.WHITE : COLORS.PRIMARY_DARK,

        '> span': {
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center'
        }
      }
    })
  }),
  (blue, fullWidth, hasIconLeft, hasIconRight) =>
    `${blue}-${fullWidth}-${hasIconLeft}-${hasIconRight}`
);

const defaultStyles = StyleSheet({
  icon: {
    height: '20px',
    width: 'auto'
  },

  iconLeft: {
    marginRight: '4px'
  },

  iconRight: {
    marginLeft: '4px'
  }
});

@styled(defaultStyles)
class SecondaryButton extends PureComponent {
  render() {
    const {
      blue,
      fullWidth,
      children,
      IconLeft,
      IconRight,
      styles: s,
      ...props
    } = this.props;
    const buttonOverrides = getStyles(blue, fullWidth, !!IconLeft, !!IconRight);
    return (
      <StylesProvider components={buttonOverrides}>
        <Button type={props.form ? 'submit' : 'button'} {...props}>
          {IconLeft && <IconLeft {...s('icon', 'iconLeft')} />}
          <span>{children}</span>
          {IconRight && <IconRight {...s('icon', 'iconRight')} />}
        </Button>
      </StylesProvider>
    );
  }
}

export default SecondaryButton;
