import React, { PureComponent } from 'react';
import types from 'prop-types';
import Button from '@rexlabs/button';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS, BORDER_RADIUS } from 'shared/theme';
import _ from 'lodash';

const createHash = (...args) =>
  args.reduce((all, arg) => `${all}-${!!arg}`, '');

// Memoized method to get the right color schema from the
// props
const getColor = _.memoize((pocket, softblue) => {
  if (pocket) {
    return {
      default: COLORS.LOGIN.POCKET_BLUE,
      hover: COLORS.LOGIN.POCKET_BLUE,
      text: COLORS.WHITE,
      focus: COLORS.WHITE
    };
  }
  if (softblue) {
    return {
      default: COLORS.LOGIN.BUTTON_PRIMARY,
      hover: COLORS.LOGIN.BUTTON_HOVER,
      text: COLORS.WHITE,
      focus: COLORS.LOGIN.BUTTON_FOCUS
    };
  }

  return {
    default: '#B4B1A9',
    hover: '#9E9B93',
    text: COLORS.WHITE,
    focus: 'rgba(0,0,0,0.05)'
  };
}, createHash);

const getStyles = _.memoize(
  (pocket, softblue, IconLeft, IconRight, padding, active, large) => {
    const color = getColor(pocket, softblue);
    return StyleSheet({
      container: {
        backgroundColor: color.default,
        border: '0 none',
        height: large ? '50px' : '44px',
        minWidth: '88px',
        lineHeight: '16px',
        fontSize: '13px',
        fontWeight: 600,
        borderRadius: BORDER_RADIUS.BUTTONS,
        transition: 'background 0.3s, box-shadow 0.15s ease-out',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: padding ? (IconLeft ? PADDINGS.XXS : PADDINGS.S) : 0,
        paddingRight: padding ? (IconRight ? PADDINGS.XXS : PADDINGS.S) : 0,
        position: 'relative',
        color: color.text,
        boxShadow: active ? '0 4px 5px 0 rgba(0, 0, 0, 0.3) inset' : undefined,

        '&:hover': {
          backgroundColor: color.hover
        },

        '&:active': {
          boxShadow: '0 4px 5px 0 rgba(0, 0, 0, 0.3) inset'
        },

        ...(softblue
          ? {
              '&:focus': {
                outline: 'none',
                boxShadow: '0 0 1px 3px ' + color.focus
              }
            }
          : {})
      },

      containerDisabled: {
        pointerEvents: 'none'
      },

      content: {
        transition: 'color 0.3s',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: color.text,

        '> span': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    });
  },
  createHash
);

const defaultStyles = StyleSheet({
  icon: {
    display: 'flex',
    flexShrink: 0,

    '& svg': {
      width: '22px',
      height: 'auto',

      '& *': {
        fill: 'currentColor !important'
      }
    }
  },

  iconLeft: {
    marginRight: '3px'
  },

  iconRight: {
    marginLeft: '3px'
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  }
});

@styled(defaultStyles)
class DefaultButton extends PureComponent {
  static propTypes = {
    softBlue: types.bool,

    padding: types.bool,

    IconLeft: types.func,
    IconRight: types.func
  };

  static defaultProps = {
    padding: true
  };

  render() {
    const {
      softblue,
      children,
      IconLeft,
      IconRight,
      padding,
      pocket,
      active,
      large,
      form,
      styles: s,
      ...props
    } = this.props;

    return (
      <Button
        styles={getStyles(
          pocket,
          softblue,
          IconLeft,
          IconRight,
          padding,
          active,
          large
        )}
        type={form ? 'submit' : 'button'}
        form={form}
        {...props}
      >
        {IconLeft && (
          <span {...s('icon', 'iconLeft')}>
            <IconLeft />
          </span>
        )}
        <span {...s('label')}>{children}</span>
        {IconRight && (
          <span {...s('icon', 'iconRight')}>
            <IconRight />
          </span>
        )}
      </Button>
    );
  }
}

export default DefaultButton;
