import React from 'react';
// TODO - pretty sure we want the Dropdown component here?
import { EntitySelect } from '@rexlabs/select';

// TODO - these look wrong and also are in shell
import Value from './defaults/value';
import Option from './defaults/option';
import Loading from './defaults/loading';
import NoResultsFound from './defaults/not-found';
import { api } from 'shared/utils/api-client';

async function autocomplete(searchTerm) {
  const { data } = await api.post('SystemValues::AutocompleteCategoryValues', {
    search_string: searchTerm,
    list_name: 'listing_category'
  });
  return (data.result || []).map((user) => ({
    value: user?.id,
    label: user?.text
  }));
}
// TODO - find the correct format for regular select data
// async function getItems() {
//   const { data } = await api.post('SystemValues::GetCategoryValues', {
//     list_name: 'listing_category'
//   });
//   return (data.result || []).map((user) => ({
//     value: user?.id,
//     label: user?.text
//   }));
// }

function ListingCategorySelect(props) {
  return (
    <EntitySelect
      {...props}
      autocomplete={autocomplete}
      Value={Value}
      Option={Option}
      Loading={Loading}
      NoResultsFound={NoResultsFound}
    />
  );
}

export { ListingCategorySelect };
