import React from 'react';
import { Grid as VividGrid, Column } from '@rexlabs/grid';
import { StyleSheet, StylesProvider } from '@rexlabs/styling';

import { PADDINGS, TEXTS } from 'shared/theme';

const overrides = {
  InnerField: StyleSheet({
    field: {
      position: 'relative',
      marginTop: PADDINGS.S,

      '&:first-child': {
        // Within grids a field will always be the first child, so we
        // revert the "first child has no margin top" rule here
        marginTop: '15px !important'
      }
    },

    fieldLabel: {
      ...TEXTS.FIELD.LABEL
    },

    error: {
      position: 'absolute',
      marginTop: PADDINGS['3XS'],
      ...TEXTS.FIELD.ERROR
    }
  })
};

function Grid(props) {
  return (
    <StylesProvider components={overrides}>
      <VividGrid {...props} />
    </StylesProvider>
  );
}

export { Grid, Column };
