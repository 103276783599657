/*
|-------------------------------------------------------------------------------
| App Routes
|-------------------------------------------------------------------------------
|
| The main routes for the App should be centralised, configured & exposed here.
|
| Documentation for configuring routes can be found in the `react-whereabouts`
| readme (https://git.io/vNyTN).
|
*/

const ROUTES = {
  LOGIN: {
    config: {
      exact: true,
      path: '/',
      Component: require('view/screens/auth/login').default
    }
  },
  RESET_PASSWORD: {
    config: {
      path: '/actions/password_reset',
      Component: require('view/screens/auth/reset-password').default
    }
  },
  REGISTER_USER: {
    config: {
      path: '/actions/register_user',
      Component: require('view/screens/auth/register-user').default
    }
  },
  USER_INVITATION: {
    config: {
      path: '/actions/user_invitation',
      Component: require('view/screens/auth/user-invitation').default
    }
  },
  MAINTAINENCE: {
    config: {
      path: '/public/maintenance',
      Component: require('view/screens/public/maintenance').default
    }
  }
};

export default ROUTES;
