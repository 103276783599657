import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { push, Link } from '@rexlabs/whereabouts';
import AuthContext from 'view/containers/auth-context';
import { PADDINGS, FONT } from 'src/theme';
import ROUTES from 'src/routes';

// Shared
import AuthView from 'shared/components/auth-view';
import {
  DefaultButton,
  LoadingButton,
  TextButton
} from 'shared/components/button';
import Spinner from 'shared/components/spinner';

import _ from 'lodash';

@autobind
class UserInvitation extends Component {
  static contextType = AuthContext;

  state = {
    isLoading: false,
    actionDetails: null,
    actionTokenExpired: false,
    invitationStatus: null
  };

  componentDidMount() {
    const { getActionDetails } = this.context;
    const {
      whereabouts: { hashQuery }
    } = this.props;

    const actionToken = _.get(hashQuery, 'action_token');

    if (actionToken) {
      this.setState(
        () => ({
          isLoading: true
        }),
        () => {
          getActionDetails(actionToken)
            .then((response) => {
              this.setState(() => ({
                isLoading: false,
                actionDetails: response.data.result
              }));
            })
            .catch(() => {
              this.setState(() => ({
                isLoading: false,
                actionTokenExpired: true
              }));
            });
        }
      );
    } else {
      push(ROUTES.LOGIN);
    }
  }

  handleAcceptInvitationClick() {
    const { handleAcceptInvitationClick } = this.context;
    const {
      whereabouts: { hashQuery }
    } = this.props;

    this.setState(
      () => ({ isInviting: true }),
      () => {
        handleAcceptInvitationClick(hashQuery?.action_token)
          .then(() => {
            this.setState(() => ({
              isInviting: false,
              invitationStatus: 'accepted'
            }));
          })
          .catch(() => {
            this.setState(() => ({
              isInviting: false,
              actionTokenExpired: true
            }));
          });
      }
    );
  }

  handleDeclineInvitationClick() {
    const { handleDeclineInvitationClick } = this.context;
    const {
      whereabouts: { hashQuery }
    } = this.props;

    const actionToken = _.get(hashQuery, 'action_token');

    handleDeclineInvitationClick(actionToken)
      .then(() => {
        this.setState(() => ({
          invitationStatus: 'declined'
        }));
      })
      .catch(() => {
        this.setState(() => ({
          actionTokenExpired: true
        }));
      });
  }

  preventDefault(e) {
    e.preventDefault();
  }

  render() {
    const {
      isLoading,
      isInviting,
      actionDetails,
      actionTokenExpired,
      invitationStatus
    } = this.state;

    const firstName = _.get(actionDetails, 'meta.first_name', '');
    const orgName = _.get(actionDetails, 'meta.account_name');

    return (
      <Box>
        {isLoading ? (
          <Box justifyContent={'center'}>
            <Spinner dark />
          </Box>
        ) : actionTokenExpired ? (
          <AuthView heading='This invitation has already been actioned'>
            <Box justifyContent='flex-end' width='100%' mt={PADDINGS.XL}>
              <Link to={ROUTES.LOGIN}>
                {({ onClick }) => (
                  <DefaultButton softblue large onClick={onClick}>
                    Log in
                  </DefaultButton>
                )}
              </Link>
            </Box>
          </AuthView>
        ) : invitationStatus ? (
          invitationStatus === 'accepted' ? (
            <AuthView
              heading='All set to go'
              body={
                <span>
                  You’re now a member of{' '}
                  <span style={{ fontWeight: FONT.WEIGHTS.SEMIBOLD }}>
                    {orgName}
                  </span>
                  . It’s great to have you on board!
                </span>
              }
            >
              <Box justifyContent='flex-end' width='100%' mt={PADDINGS.XL}>
                <Link to={ROUTES.LOGIN}>
                  {({ onClick }) => (
                    <DefaultButton softblue large onClick={onClick}>
                      Log in
                    </DefaultButton>
                  )}
                </Link>
              </Box>
            </AuthView>
          ) : (
            <AuthView
              heading='Invitation declined'
              body={
                <span>
                  You didn’t join{' '}
                  <span style={{ fontWeight: FONT.WEIGHTS.SEMIBOLD }}>
                    {orgName}
                  </span>
                  . We’ll let whomever sent the invitation know.
                </span>
              }
            />
          )
        ) : (
          <AuthView
            heading={`Welcome, ${_.capitalize(firstName)}`}
            body={
              <span>
                You’ve been invited to join{' '}
                <span style={{ fontWeight: FONT.WEIGHTS.SEMIBOLD }}>
                  {orgName}
                </span>
                . If accepted, when you next log in to Rex you’ll be able to
                switch between accounts at any time.
              </span>
            }
          >
            <Box
              alignItems='center'
              justifyContent='flex-end'
              width='100%'
              mt={PADDINGS.XL}
            >
              <Box mr={PADDINGS.XS}>
                <TextButton grey onClick={this.handleDeclineInvitationClick}>
                  Decline invite
                </TextButton>
              </Box>
              <LoadingButton
                softblue
                large
                form='register_user'
                isLoading={isInviting}
                onClick={this.handleAcceptInvitationClick}
              >
                Accept invite
              </LoadingButton>
            </Box>
          </AuthView>
        )}
      </Box>
    );
  }
}

export default UserInvitation;
