import { getReleaseStageFromHostname } from './get-release-stage-from-hostname';

import initSegment from './init-segment';
import initBugsnag from './init-bugsnag';

const EVENTS = {
  INFORMATION: 'Information',
  ERROR: 'Error',
  PERFORMANCE: 'Performance',
  STEPTHROUGH: 'Stepthrough Entered',
  GLOBAL_ADD: 'Global Add Button Action',
  RECORD_LIST_SCREEN: {
    SAVED_FILTERS: {
      APPLIED: 'Saved Filter applied',
      CREATED: 'Saved Filter created',
      FAVOURITED: 'Saved Filter favourited'
    }
  },
  NAVIGATION_MENU_TOGGLE: {
    OPEN: 'Navigation Menu Toggle Open',
    CLOSED: 'Navigation Menu Toggle Closed'
  },
  RELEASE_NOTES: {
    OPEN: 'Release notes opened'
  },
  PAYWALL: {
    EMAIL_SUPPORT: 'Paywall Support Email',
    LEARN_MORE: 'Paywall Learn More'
  },
  FEATURE_FLAGS: {
    ENABLED: 'Feature Flag Enabled',
    DISABLED: 'Feature Flag Disabled'
  },
  ESIGN: {
    REQUEST_SENT: 'E-sign Request Sent',
    TEMPLATE_ADDED: 'E-sign Template Added'
  },
  CALENDAR: {
    // Adding load page as an event so Intercom knows how many times a user
    // has visited calendar (which we can't do otherwise :C)
    LOAD_PAGE: 'Visited Calendar',
    ADD_CONTACT_GUEST: 'Calendar Contact Guest/s Added',
    ADD_USER_GUEST: 'Calendar User Guest/s Added',
    CREATE_APPOINTMENT: 'Calendar Create Appointment',
    CONNECT_EXTERNAL: 'Calendar Connect External',
    DISCONNECT_EXTERNAL: 'Calendar Disconnect External',
    CREATE_CALENDAR: 'Calendar Create New Calendar',
    CREATE_FOLLOWUP_REMINDER: 'Create appointment with follow up reminder',
    GUEST_CONFIRMATION_SENT: 'Confirmation sent (guest)',
    VENDOR_CONFIRMATION_SENT: 'Confirmation sent (listing owner)'
  },
  DASHBOARDS: {
    // Adding load page as an event so Intercom knows how many times a user
    // has visited the dashboards page (which we can't do otherwise :C)
    LOAD_PAGE: 'Visited Dashboards',
    OPEN_SWITCHER: 'Open Dashboard Switcher',
    CHANGE_DATE_RANGE: 'Change Dashboards Date Range',
    CHANGE_USER_FILTER: 'Change Dashboard User Filter',
    CHANGE_SOLD_STATUS_FILTER: 'Change Dashboard Sold Status Filter',
    CHANGE_CATEGORY_FILTER: 'Change Dashboard Category Filter',
    IDLE_REFRESH: 'Idle Refresh Dashboard'
  },
  CHAINS: {
    ADD_NOTE: 'Add Chain Note',
    OPEN_EDIT_CHAIN_DIALOG: 'Open Edit Chain Dialog'
  },
  PREVIEW_POPOUTS: {
    SEND_EMAIL: 'Send email from preview popout',
    SEND_SMS: 'Send SMS from preview popout',
    ADD_NOTE: 'Add note from preview popout',
    ADD_REMINDER: 'Add reminder from preview popout',
    ADD_FEEDBACK: 'Add feedback from preview popout',
    ADD_APPOINTMENT: 'Add appointment from preview popout',
    MAKE_CALL: 'Make click to dial call from preview popout'
  },
  LEADS_DIALOG: {
    OPEN: 'Open leads dialog',
    SEND_EMAIL_ACTION: 'Send email from leads dialog',
    SEND_SMS_ACTION: 'Send SMS from leads dialog',
    ADD_NOTE_ACTION: 'Add note from leads dialog',
    ADD_FEEDBACK_ACTION: 'Add feedback from leads dialog',
    UPDATE_ASSIGNEE: 'Change lead assignee from leads dialog',
    MARK_AS_COMPLETE: 'Mark as complete from leads dialog',
    BOOK_APPOINTMENT_ACTION: 'Book appointment from leads dialog',
    ADD_REMINDER_ACTION: 'Add reminder from leads dialog',
    LEAD_EDITED: 'Lead edited',
    ADD_MISSING_RECORDS_ACTION: 'Leads missing records banner clicked'
  },
  CUSTOM_REPORTING: {
    LOAD_PAGE: 'Custom report loaded',
    MODIFY_DATE_FILTER: 'Custom report date filter modified',
    MODIFY_USER_FILTER: 'Custom report user filter modified',
    MODIFY_LOCATION_FILTER: 'Custom report location filter modified',
    OPEN_COLUMN_PANEL: 'Custom report columns panel opened',
    OPEN_FILTER_PANEL: 'Custom report filters panel opened',
    ENABLE_PIVOT: 'Custom report pivot table enabled',
    EXPORT_DATA: 'Custom report data exported',
    OPEN_CONTEXT_MENU: 'Custom report context menu opened',
    CREATE_REPORT: 'Custom report created',
    UPDATE_REPORT: 'Custom report updated'
  },
  GLOBAL_SEARCH: {
    SELECTED_ITEM_FROM_APP_SEARCH: 'Selected item from app search',
    ABANDONED_SEARCH: 'Abandonded app search',
    NEW_SEARCH_FEEDBACK: 'Feedback on new search experience'
  },
  PIPELINES: {
    GROUP_MODE_CHANGED: 'Pipeline group mode changed',
    ORDER_BY_CHANGED: 'Pipeline order by changed',
    ITEM_DRAGGED: 'Pipeline item dragged'
  },
  AI_AD_TEXT: {
    AI_AD_TEXT_OPENED: 'AI Ad Text - Opened',
    AI_AD_TEXT_CLOSED: 'AI Ad Text - Closed',
    AI_AD_TEXT_INSERTED: 'AI Ad Text - Inserted',
    AI_AD_TEXT_LEARN_MORE: 'AI Ad Text - Learn More',
    AI_AD_TEXT_FAILED: 'AI Ad Text - Failed'
  },
  AI_COMMUNICATIONS: {
    AI_COMMUNICATIONS_OPENED: 'AI Communications - Opened',
    AI_COMMUNICATIONS_CLOSED: 'AI Communications - Closed',
    AI_COMMUNICATIONS_INSERTED: 'AI Communications - Inserted',
    AI_COMMUNICATIONS_FAILED: 'AI Communications - Failed'
  },
  AI_CONTENT: {
    AI_CONTENT_TONE_ADJUSTED: 'AI Content - Tone Adjusted',
    AI_CONTENT_AUDIENCE_ADJUSTED: 'AI Content - Audience Adjusted',
    AI_CONTENT_CUSTOM_REFINEMENT: 'AI Content - Custom Refinement',
    AI_CONTENT_SHORTER: 'AI Content - Shorter',
    AI_CONTENT_LONGER: 'AI Content - Longer',
    AI_CONTENT_GENERATE_NEW: 'AI Content - Generate New'
  },
  AI_FILTERS: {
    AI_FILTERS_OPENED: 'AI Filters - Opened',
    AI_FILTERS_GENERATED: 'AI Filters - Generated',
    AI_FILTERS_FAILED: 'AI Filters - Failed',
    AI_FILTERS_CLOSED: 'AI Filters - Closed',
    AI_FILTERS_FEEDBACK: 'AI Filters - Feedback'
  },
  NEWSLETTERS: {
    NEWSLETTERS_CREATED: 'Newsletters - Created'
  }
};

// TODO: refactor/genericize this to allow authapp/group-app/check-in-app to just call this
// TODO: maybe allow an array of the third parties we want to call - just incase we want different setup per application
const initThirdPartyScripts = (config, store, sessionModel) => {
  const { SEGMENT_ID } = config;

  initSegment(SEGMENT_ID).then(() => {
    store.dispatch(sessionModel.actionCreators.initSegment());
  });

  initBugsnag(config);
};

export {
  getReleaseStageFromHostname,
  EVENTS,
  initSegment,
  initBugsnag,
  initThirdPartyScripts
};
