import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

export async function apiFake<Response>(
  endpoint: string,
  response: Response,
  status = 200
): Promise<AxiosResponse<Response>> {
  const waitTime = 400 + Math.random() * 200;
  await new Promise<void>((resolve) => setTimeout(() => resolve(), waitTime));

  // eslint-disable-next-line no-console
  console.debug('FAKE REQUEST', endpoint, response);

  const axiosResponse = {
    config: {},
    data: response,
    headers: {},
    request: {},
    status: status,
    statusText: String(status)
  } as AxiosResponse<Response>;

  if (status >= 400) {
    const error = class extends Error implements AxiosError {
      config: AxiosRequestConfig;
      code?: string;
      request?: any;
      response?: typeof axiosResponse;
      isAxiosError: boolean;
      toJSON: () => object;

      constructor(...args) {
        super(...args);
        this.code = String(status);
        this.config = {};
        this.isAxiosError = true;
        this.request = {};
        this.response = axiosResponse;
        this.toJSON = () => ({
          ...this
        });
      }
    };
    throw error;
  }

  return axiosResponse;
}
