import { Generator } from 'shared/utils/models';
import Analytics from 'shared/utils/vivid-analytics';

const initialState = {
  isOnline: navigator ? navigator.onLine : false
};

const selectors = {
  isOnline: (state) => state.connection.isOnline
};

// TODO - we can't see analytics event properties in Intercom,
// change event name to include isOnline info if required
// https://app.clubhouse.io/rexlabs/story/51891/add-details-for-intercom-events

const actionCreators = {
  changeStatus: {
    reduce: (state, action) => {
      Analytics.track({
        event: 'connection status changed',
        properties: { isOnline: action.payload },
        options: { integrations: { Intercom: true } }
      });
      return { ...state, isOnline: action.payload };
    }
  }
};

const connection = new Generator('connection').createModel({
  initialState,
  selectors,
  actionCreators
});

export const middleware = (store) => {
  if (navigator) {
    const changeStatus = (status) =>
      store.dispatch(connection.actionCreators.changeStatus(status));

    window.addEventListener('online', () => changeStatus(true));
    window.addEventListener('offline', () => changeStatus(false));
  }

  return (next) => (action) => next(action);
};

export default connection;
