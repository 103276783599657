// Copied from Spoke
import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import {
  ReactForms,
  withForm,
  Form as RexlabsForm,
  FieldArray
} from '@rexlabs/form';
import { COLORS } from 'shared/theme';

import FormField from './field';

const defaultStyles = StyleSheet({
  errorList: {
    listStyle: 'none',
    paddingLeft: '14px',
    position: 'relative',

    '> li': {
      position: 'relative'
    },

    '> li:before': {
      content: '" "',
      position: 'absolute',
      top: '7px',
      left: '-10px',
      height: '4px',
      width: '4px',
      borderRadius: '4px',
      background: COLORS.BLACK
    }
  }
});

@styled(defaultStyles)
class Form extends PureComponent {
  static defaultProps = {
    showErrors: true
  };

  render() {
    const {
      styles: s,
      name,
      showErrors,
      hasErrorPadding,
      formErrorStyles,
      ...props
    } = this.props;

    return (
      <RexlabsForm id={name} name={name} autocomplete={'off'} {...props} />
    );
  }
}

export { ReactForms, withForm, Form, FormField, FieldArray };
