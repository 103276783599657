import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { TextInput } from '@rexlabs/text-input';
import Box from '@rexlabs/box';
import { Grid, Column } from 'shared/components/grid';
import { push, Link } from '@rexlabs/whereabouts';
import { createValidationRules } from '@rexlabs/validator';
import AuthContext from 'view/containers/auth-context';
import { PADDINGS, FONT } from 'src/theme';
import ROUTES from 'src/routes';
import { Body } from 'view/components/text';

// Shared
import { Select } from 'shared/components/input/select';
import config from 'shared/utils/config';
import AuthView from 'shared/components/auth-view';
import PasswordInput from 'shared/components/input/password';
import {
  DefaultButton,
  LoadingButton,
  TextButton
} from 'shared/components/button';
import { ReactForms, Form, FormField } from 'shared/components/form';
import Spinner from 'shared/components/spinner';

import _ from 'lodash';
import Checkbox from 'shared/components/input/checkbox';
import DescriptiveOption from 'auth-app/src/view/components/input/select/options/descriptive';

const validate = createValidationRules({
  first_name: 'required',
  email: 'required|email',
  password: 'required',
  confirm_password: 'required|same:password',
  segmentation_role_id: 'required',
  segmentation_tech_skill: 'required'
});

@autobind
class RegisterUser extends Component {
  static contextType = AuthContext;

  /**
   * If you switch the AUTH_DEV_MODE variable to true in shared/env.js
   *
   * Then you can easily test this page without needing a valid action token.
   */
  constructor(props) {
    super(props);
    const isEU =
      _.get(props, 'whereabouts.hashQuery.region').includes('uk') ||
      _.get(props, 'whereabouts.hashQuery.region').includes('fr');
    this.isDevMode = config.AUTH_DEV_MODE;
    this.segmentRoleOptions = [
      {
        value: 'director',
        label: 'Director/Principal',
        description: isEU
          ? 'Partner, CEO, managing director, etc'
          : 'Principal, CEO, managing director, etc'
      },
      {
        value: 'manager',
        label: 'Manager/Coordinator',
        description: 'Sales manager, regional manager, etc'
      },
      {
        value: 'agent',
        label: isEU ? 'Negotiator/Agent' : 'Sales Agent',
        description: isEU
          ? 'Estate agent, sales progressor, sales consultant, associate, etc'
          : 'Salesperson, Licenced agent, sales consultant, lead agent, etc'
      },
      {
        value: 'admin',
        label: 'Admin',
        description: isEU
          ? 'Office admin, personal assistant, receptionist, etc"'
          : 'Office admin, sales admin, receptionist, etc'
      },
      {
        value: 'pm',
        label: isEU ? 'Lettings Manager/Property Manager' : 'Property Manager',
        description: isEU
          ? 'Lettings agent, property management assistant, etc'
          : 'BDM, property management assistant, etc'
      },
      {
        value: 'other',
        label: 'Other',
        description: isEU
          ? 'Accountant, IT, marketing, solicitor, etc'
          : 'Accountant, IT, marketing, etc'
      }
    ];

    this.state = {
      isLoading: false,
      actionDetails: null,
      actionTokenExpired: false,
      invitationStatus: null
    };
  }

  componentDidMount() {
    const { getActionDetails } = this.context;
    const {
      whereabouts: { hashQuery }
    } = this.props;

    const actionToken = _.get(hashQuery, 'action_token');

    if (actionToken && !this.isDevMode) {
      this.setState(
        () => ({
          isLoading: true
        }),
        () => {
          getActionDetails(actionToken)
            .then((response) => {
              this.setState(() => ({
                isLoading: false,
                actionDetails: response.data.result
              }));
            })
            .catch(() => {
              this.setState(() => ({
                isLoading: false,
                actionTokenExpired: true
              }));
            });
        }
      );
    }

    if (!actionToken && !this.isDevMode) {
      push(ROUTES.LOGIN);
    }

    if (this.isDevMode) {
      this.setState({
        isLoading: false,
        actionDetails: {
          meta: {
            first_name: 'Developer',
            last_name: 'Rex',
            email: 'developer@rexsoftware.com.au',
            account_name: 'Green Valley Real Estate',
            password: 'password',
            confirm_password: 'password'
          }
        }
      });
    }
  }

  handleDeclineInvitationClick() {
    const { handleDeclineInvitationClick } = this.context;
    const {
      whereabouts: { hashQuery }
    } = this.props;

    const actionToken = _.get(hashQuery, 'action_token');

    handleDeclineInvitationClick(actionToken)
      .then(() => {
        this.setState(() => ({
          invitationStatus: 'declined'
        }));
      })
      .catch(() => {
        this.setState(() => ({
          actionTokenExpired: true
        }));
      });
  }

  preventDefault(e) {
    e.preventDefault();
  }

  render() {
    const { handleGetStartedClick, handleRegisterUserSubmit } = this.context;
    const { isLoading, actionDetails, actionTokenExpired, invitationStatus } =
      this.state;
    const {
      whereabouts: { hashQuery }
    } = this.props;

    const actionToken = _.get(hashQuery, 'action_token');
    const firstName = _.get(actionDetails, 'meta.first_name', '');
    const orgName = _.get(actionDetails, 'meta.account_name');

    return (
      <Box>
        {isLoading ? (
          <Box justifyContent={'center'}>
            <Spinner dark />
          </Box>
        ) : actionTokenExpired ? (
          <AuthView heading='This invitation has already been actioned'>
            <Box justifyContent='flex-end' mt={PADDINGS.XL}>
              <Link to={ROUTES.LOGIN}>
                {({ onClick }) => (
                  <DefaultButton softblue large onClick={onClick}>
                    Log in
                  </DefaultButton>
                )}
              </Link>
            </Box>
          </AuthView>
        ) : invitationStatus ? (
          invitationStatus === 'accepted' ? (
            <AuthView
              heading='Account created'
              body={
                <span>
                  You’re now a member of{' '}
                  <span style={{ fontWeight: FONT.WEIGHTS.SEMIBOLD }}>
                    {orgName}
                  </span>
                  . It’s great to have you on board!{' '}
                </span>
              }
            >
              <Box justifyContent='flex-end' mt={PADDINGS.XL}>
                <DefaultButton softblue large onClick={handleGetStartedClick}>
                  Get started
                </DefaultButton>
              </Box>
            </AuthView>
          ) : (
            <AuthView
              heading='Invitation declined'
              body={
                <span>
                  You didn’t join{' '}
                  <span style={{ fontWeight: FONT.WEIGHTS.SEMIBOLD }}>
                    {orgName}
                  </span>
                  . We’ll let whomever sent the invitation know.
                </span>
              }
            />
          )
        ) : (
          <AuthView
            heading={`Welcome, ${_.capitalize(firstName)}`}
            body={
              <span>
                You’ve been invited to join{' '}
                <span style={{ fontWeight: FONT.WEIGHTS.SEMIBOLD }}>
                  {orgName}
                </span>{' '}
                on Rex. To get started, finish setting up your account below.
              </span>
            }
          >
            <ReactForms
              validateOnMount
              touchOnMount={false}
              touchOnChange={false}
              initialValues={_.get(actionDetails, 'meta')}
              validate={(values, { meta }) =>
                // Hack to get around a current
                // ReactForms limitation
                validate({
                  password: _.get(meta, 'password'),
                  ...values
                })
              }
              handleSubmit={this.isDevMode ? _.noop : handleRegisterUserSubmit}
            >
              {({ isValid, isSubmitting, meta, setMeta, submitForm }) => {
                return (
                  <Form
                    name='register_user'
                    showErrors={false}
                    onSubmit={this.preventDefault}
                  >
                    <Grid columns={2}>
                      <Column width={1} mb={PADDINGS.XL}>
                        <FormField
                          sendImmediate
                          name='first_name'
                          label='first name'
                          Input={TextInput}
                        />
                      </Column>
                      <Column width={1} mb={PADDINGS.XL}>
                        <FormField
                          sendImmediate
                          name='last_name'
                          label='last name'
                          Input={TextInput}
                        />
                      </Column>
                      <Column width={2} mb={PADDINGS.XL}>
                        <FormField
                          sendImmediate
                          name='email'
                          label='email address'
                          Input={TextInput}
                          onBlur={(e) => {
                            if (window.bugsnagClient) {
                              window.bugsnagClient.user = {
                                email: e.target.value
                              };
                            }
                          }}
                        />
                      </Column>
                      <Column width={2} mb={PADDINGS.XL}>
                        <FormField
                          sendImmediate
                          name='password'
                          label='password'
                          Input={PasswordInput}
                          onChange={(e) => {
                            setMeta({
                              ...meta,
                              password: e.target.value
                            });
                          }}
                        />
                      </Column>
                      <Column width={2} mb={PADDINGS.XL}>
                        <FormField
                          sendImmediate
                          name='confirm_password'
                          label='confirm password'
                          Input={PasswordInput}
                        />
                      </Column>
                      <Column width={2} mb={PADDINGS.XL}>
                        <Body darkergrey normal regular large>
                          Tell us a bit about your role so we can better tailor
                          Rex to suit your needs
                        </Body>
                        <FormField
                          sendImmediate
                          name='segmentation_role_id'
                          label='How would you describe your role?'
                          Input={Select}
                          inputProps={{
                            options: this.segmentRoleOptions,
                            Option: DescriptiveOption,
                            OptionSelected: DescriptiveOption,
                            valueAsObject: true
                          }}
                        />
                      </Column>
                      <Column width={2} mb={PADDINGS.XL}>
                        <FormField
                          name='segmentation_transactions_residential'
                          label='What kind of transactions are you involved in?'
                          Input={Checkbox}
                          sendImmediate
                          inputProps={{
                            label: 'Residential'
                          }}
                        />
                        <FormField
                          name='segmentation_transactions_commercial'
                          Input={Checkbox}
                          sendImmediate
                          inputProps={{
                            label: 'Commercial'
                          }}
                        />
                        <FormField
                          sendImmediate
                          name='segmentation_tech_skill'
                          label='How comfortable are you with technology?'
                          Input={Select}
                          inputProps={{
                            options: [
                              { label: '1 - Not comfortable', value: 1 },
                              { label: '2', value: 2 },
                              { label: '3', value: 3 },
                              { label: '4', value: 4 },
                              { label: '5 - Very tech savvy', value: 5 }
                            ]
                          }}
                        />
                      </Column>
                    </Grid>
                    <Box
                      alignItems='center'
                      justifyContent='flex-end'
                      width='100%'
                      mt={PADDINGS.S}
                    >
                      <Box mr={PADDINGS.XS}>
                        <TextButton
                          grey
                          onClick={this.handleDeclineInvitationClick}
                        >
                          Decline invitation
                        </TextButton>
                      </Box>
                      <LoadingButton
                        softblue
                        large
                        form='register_user'
                        isLoading={isSubmitting}
                        isDisabled={!isValid}
                        onClick={() => {
                          setMeta({ ...meta, actionToken }).then(() => {
                            submitForm().then(() => {
                              this.setState(() => ({
                                invitationStatus: 'accepted'
                              }));
                            });
                          });
                        }}
                      >
                        Create account
                      </LoadingButton>
                    </Box>
                  </Form>
                );
              }}
            </ReactForms>
          </AuthView>
        )}
      </Box>
    );
  }
}

export default RegisterUser;
