import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';
import config from '../../../utils/config';
import { parseUrlToRoute, replace } from '@rexlabs/whereabouts';
import { getCookie } from '../../../utils/cookies';
import dayjs from 'dayjs';

const initialState = {
  meta: {
    error: null,
    ready: false,
    remember: false,
    loading: false,
    expectingExchange: false
  },
  loginInfo: {
    email: '',
    firstName: '',
    lastName: '',
    accounts: null,
    loginMethods: [],
    loginPreference: ''
  },
  lastAccount: {}
};

const sendToGroupApp = (request) => {
  api.setApp('rexgroup');
  return request().then((response) => {
    api.setApp('rex');
    return response;
  });
};

function createErrorHandler(fallbackMessage, formatMessage = _.identity) {
  return (state, action) => {
    const errorMessage = _.get(action, 'payload.error.message')
      ? formatMessage(_.get(action, 'payload.error.message'))
      : fallbackMessage;
    return {
      ...state,
      meta: {
        ...state.meta,
        error: errorMessage,
        loading: false
      }
    };
  };
}

const selectors = {
  meta: (state) => _.get(state, 'auth.meta'),
  loginInfo: (state) => _.get(state, 'auth.loginInfo'),
  lastAccount: (state) => _.get(state, 'auth.lastAccount')
};

const actionCreators = {
  init: {
    reduce: (state, action) => ({
      ...state,
      meta: {
        ...state.meta,
        error: null,
        ready: action.payload,
        expectingExchange: false
      }
    })
  },

  getSupportedLoginMethods: {
    request: ({ email }) => {
      return new Promise((resolve, reject) => {
        api
          .post('Authentication::getSupportedLoginMethods', { email })
          .then((response) => {
            resolve({ email, loginMethods: response.data.result });
          })
          .catch(reject);
      });
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        loginInfo: {
          ...state.loginInfo,
          ...action.payload
        }
      }),
      failure: createErrorHandler(
        'Could not retrieve login methods, please retry.',
        (msg) => `Could not retrieve login methods - ${msg}`
      )
    }
  },

  getLoginFlowUrl: {
    request: ({ loginMethodId }, actions, dispatch, getState) => {
      return new Promise((resolve, reject) => {
        const state = getState();
        api
          .post('Authentication::getLoginFlowUrl', {
            login_method_id: loginMethodId,
            email: _.get(state, 'auth.loginInfo.email')
          })
          .then((response) => {
            resolve({ redirectUrl: response.data.result });
          })
          .catch(reject);
      });
    },
    reduce: {
      initial: (state, action) => ({
        ...state,
        meta: { ...state.meta, loading: true },
        loginInfo: {
          ...state.loginInfo,
          loginPreference: action.payload.loginMethodId
        }
      }),
      success: (state) => ({
        ...state,
        meta: { ...state.meta, expectingExchange: true }
      }),
      failure: createErrorHandler(
        'Could not retrieve external login URL, please retry.',
        (msg) => `Could not retrieve external login URL - ${msg}`
      )
    }
  },

  login: {
    request: ({ loginMethodId, params = {} }, actions, dispatch, getState) =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        const state = getState();
        const login = await api
          .post('Authentication::login', {
            login_method_id: loginMethodId,
            email:
              _.get(params, 'email') || _.get(state, 'auth.loginInfo.email'),
            params
          })
          .catch(reject);

        const globalToken = _.get(login, 'data.result.token');
        if (!globalToken) {
          reject(new Error('Login failed'));
          return;
        }

        resolve({
          token: globalToken,
          accounts: _.get(login, 'data.result.accounts', [])
        });
      }),
    reduce: {
      initial: (state) => ({
        ...state,
        meta: { ...state.meta, loading: true }
      }),
      success: (state, { payload: { accounts, token } }) => ({
        ...state,
        meta: { ...state.meta, loading: false },
        loginInfo: { ...state.loginInfo, accounts, token }
      }),
      failure: createErrorHandler(
        'Login failed, please retry.',
        (msg) => `Login failed - ${msg}`
      )
    }
  },

  // Methods external to Auth app
  exchangeApiToken: {
    request: ({ token, accountId }, actions, dispatch, getState) => {
      let tokenLifetimeSeconds = 3600; // 1 hour
      const loginMetadata = getCookie('authentication_service_login_metadata');

      if (_.get(getState(), 'auth.meta.remember')) {
        tokenLifetimeSeconds = 604800; // 1 week;
      }

      // Auth Service logins have dynamic durations which can vary
      // per user/organisation (2 weeks by default).
      // Dynamically calculate remaining login seconds to ensure
      // we don't keep perpetually extending the login
      if (loginMetadata?.login_remember_duration_minutes) {
        tokenLifetimeSeconds = dayjs(loginMetadata.last_login_at)
          .add(loginMetadata?.login_remember_duration_minutes, 'minutes')
          .diff(dayjs(), 'seconds');
      }

      return api.post('Authentication::loginWithGlobalAuthToken', {
        token,
        account_id: accountId,
        token_lifetime: tokenLifetimeSeconds
      });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: createErrorHandler(
        'Token exchange failed, please retry.',
        (msg) => `Token exchange failed - ${msg}`
      )
    }
  },

  getRegions: {
    request: () =>
      api.post('Region::getRegions', {}, { baseURL: config.API_URL }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: createErrorHandler(
        'Couldn’t receive regions, please retry.',
        (msg) => `Couldn’t receive regions - ${msg}`
      )
    }
  },

  sendResetPasswordInstructions: {
    request: ({ email, application }) =>
      api.post('Authentication::resetPassword', {
        application,
        email
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: createErrorHandler(
        'Couldn’t send reset password instructions, please retry.',
        (msg) => `Couldn’t send reset password instructions - ${msg}`
      )
    }
  },

  changePassword: {
    request: ({ actionToken, password, app }) => {
      const request = () =>
        api.post('PublicActions::resetUserPassword', {
          action_token: actionToken,
          password
        });

      return app === 'rexgroup' ? sendToGroupApp(request) : request();
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: createErrorHandler(
        'Changing password failed, please retry.',
        (msg) => `Changing password failed - ${msg}`
      )
    }
  },

  registerUser: {
    request: ({
      actionToken,
      email,
      password,
      firstName,
      lastName,
      segmentationRoleId,
      segmentationTechSkill,
      segmentationTransactionsResidential,
      segmentationTransactionsCommercial,
      app
    }) => {
      const request = () =>
        api.post('PublicActions::registerUser', {
          action_token: actionToken,
          email_address: email,
          password,
          first_name: firstName,
          last_name: lastName,
          segmentation: {
            role_id: segmentationRoleId,
            tech_skill: segmentationTechSkill,
            transactions_residential: segmentationTransactionsResidential,
            transactions_commercial: segmentationTransactionsCommercial
          }
        });

      return app === 'rexgroup' ? sendToGroupApp(request) : request();
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: createErrorHandler(
        'Registering user failed, please retry.',
        (msg) => `Registering user failed - ${msg}`
      )
    }
  },

  processUserAccessInvite: {
    request: ({ actionToken, acceptOrReject, app }) => {
      const request = () =>
        api.post('PublicActions::processUserAccessInvite', {
          action_token: actionToken,
          accept_or_reject: acceptOrReject
        });
      return app === 'rexgroup' ? sendToGroupApp(request) : request();
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: createErrorHandler(
        'Couldn’t process user invite, please retry.',
        (msg) => `Couldn’t process user invite - ${msg}`
      )
    }
  },

  getActionDetails: {
    request: ({ actionToken }) =>
      api.post('PublicActions::getActionDetails', {
        action_token: actionToken
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: createErrorHandler(
        'Couldn’t load details, please retry.',
        (msg) => `Couldn’t load details - ${msg}`
      )
    }
  },

  // Pure reducers
  setRemember: {
    reduce: (state, action) => {
      return {
        ...state,
        meta: {
          ...state.meta,
          remember: action.payload
        }
      };
    }
  },

  setLoginInfo: {
    reduce: (state, action) => {
      return {
        ...state,
        loginInfo: {
          ...state.loginInfo,
          ...action.payload
        }
      };
    }
  },

  setLastAccount: {
    reduce: (state, action) => {
      const { email, appId, accountId } = action.payload;
      return {
        ...state,
        lastAccount: {
          ...(state.lastAccount || {}),
          [email]: {
            ..._.get(state, `lastAccount.${email}`, {}),
            [appId]: accountId
          }
        }
      };
    }
  },

  setError: {
    reduce: (state, action) => {
      const { error } = action.payload;
      return {
        ...state,
        meta: {
          ...state.meta,
          error,
          loading: false
        }
      };
    }
  },

  clear: {
    reduce: (state) => ({
      ...state,
      loginInfo: initialState.loginInfo,
      meta: {
        ...state.meta,
        error: null
      }
    })
  },

  loginViaAuthService: {
    request: async ({ appId, ...actions }) => {
      let loginToken, rexToken, regionId;
      const route = parseUrlToRoute(window.location.href);

      try {
        loginToken = (
          await fetch(config.AUTHENTICATION_SERVICE_API_URL + '/login-token', {
            credentials: 'include',
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }).then((r) => {
            if (r.status === 401) {
              actions.logout();
            }
            return r.json();
          })
        ).result;

        const {
          data: { result: exchangeResult }
        } = await api.post('Authentication::exchangeLoginTokenForRexToken', {
          login_token: loginToken,
          account_id: route.query?.account_id,
          remember_me: !!route.query?.remember_me,
          app_id: appId === 'rex_crm' ? 'rex' : 'rexgroup'
        });

        rexToken = exchangeResult.token;
        regionId = exchangeResult.region_id;

        // Strip out the search query from the URL once done
        replace({
          config: { ...parseUrlToRoute(window.location.href), query: undefined }
        });
      } catch (e) {
        // Any errors are fully unexpected; redirect to auth FE
        // which will show visual feedback to user
        window?.bugsnagClient?.notify?.(e, {
          context: 'Login token exchange',
          metaData: {
            has_login_token: !!loginToken,
            account_id: route.query?.account_id,
            has_rex_token: !!rexToken
          }
        });
        const params = new URLSearchParams({
          app_id: appId,
          error: 'ERR_LOGIN_TOKEN_EXCHANGE'
        }).toString();

        window.location.href = `${config.AUTHENTICATION_SERVICE_FRONTEND_URL}?${params}`;
      }

      await actions.setRegion({ regionId });
      actions.setApiToken(rexToken);
      await actions.refresh();
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator('auth').createModel({
  actionCreators,
  selectors,
  initialState
});
