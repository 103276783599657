import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import _ from 'lodash';

import {
  Select as VividSelect,
  SelectInput as VividSelectInput,
  SelectMenu as VividSelectMenu
} from '@rexlabs/select-input';
import { filterOptionsByValue, selectFilter } from './utils';
import { ICONS } from 'shared/components/icon';
import { COLORS } from 'shared/theme';

const { ARROW_DOWN: ArrowDown } = ICONS;

@autobind
class Select extends Component {
  static defaultProps = {
    debounce: false,
    filter: selectFilter,
    shouldClearOptionsOnSelect: false,
    isClearable: false,
    isSearchable: false,
    SelectInput: VividSelectInput,
    placeholder: null,

    DropdownIndicator: () => (
      <ArrowDown
        style={{
          color: COLORS.LOGIN.STATES.IDLE,
          transform: 'rotate(270deg)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 23,
          height: 23
        }}
      />
    ),

    // Extra
    isMulti: false,
    onChange: _.noop,
    onBlur: _.noop,
    onSelect: _.noop
  };

  constructor(props) {
    super(props);

    let selected;
    let selectedSourceProp;
    if (props.value && props.options) {
      selected = filterOptionsByValue(
        props.options,
        props.value,
        props.valueAsObject
      );
      selectedSourceProp = 'value';
    } else {
      selected = props.selected;
      selectedSourceProp = 'selected';
    }

    // NOTE: why do we need this?!
    if (props.selected && props.value) {
      console.warn(
        'The "selected" and "value" prop were both provided to Select on startup. ' +
          `"${selectedSourceProp}" was chosen to manage initial selected options.`
      );
    }

    this.state = { selected };
  }

  componentWillReceiveProps(nextProps) {
    const { value, options, valueAsObject, selected } = this.props;

    const changedValue = nextProps.value !== value;
    const changedOptions = nextProps.value && nextProps.options !== options;
    const changedSelected = nextProps.selected !== selected;

    if (changedValue || changedOptions) {
      // Note: Handling behaviour for redux-form to work.
      const selectedOptions = filterOptionsByValue(
        nextProps.options,
        nextProps.value,
        valueAsObject
      );
      this.setState({ selected: selectedOptions });
    } else if (changedSelected) {
      // Note: Developer wants to handle the options.
      // Note regarding note: then he should do it via the `value` prop!?
      this.setState({ selected: nextProps.selected });
    }
  }

  handleBlur() {
    const { value, onBlur, name } = this.props;
    if (onBlur) {
      onBlur({
        persist: () => null,
        target: {
          type: 'select',
          name,
          id: name,
          value
        }
      });
    }
  }

  handleSelect(selected) {
    const { onChange, onSelect, onBlur, name, multi, valueAsObject } =
      this.props;

    const fakeEvent = {
      persist: () => null,
      target: {
        type: 'select',
        name,
        id: name,
        value: selected
          ? multi
            ? selected.map((s) => (valueAsObject ? s : s.value))
            : valueAsObject
            ? selected
            : selected.value
          : multi
          ? []
          : ''
      }
    };

    if (onChange) {
      onChange(fakeEvent);
    } else {
      // Note: When redux-form isn't present, we manage the selected state manually.
      this.setState({ selected });
    }

    if (onSelect) {
      // Note: Developer may want to handle the options, so we put this handler last.
      onSelect(selected);
    }

    // Hack: onBlur needs to wait for the onChange to change the local
    // state of the form field, so it passes it up to the form correctly
    // The timeout ensures we run onBlur on the next tick, at which point
    // the value should be stored locally!
    setTimeout(() => {
      window.document.activeElement?.blur?.();
      if (onBlur) {
        onBlur(fakeEvent);
      }
    });
  }

  render() {
    const props = this.props;

    const { SelectInput } = props;

    const SelectProps = {
      Container: props.Container,
      options: props.options,
      selected: props.selected,
      multi: props.multi,
      // isLoading: props.isLoading,
      ignoreAccents: props.ignoreAccents,
      ignoreCase: props.ignoreCase,
      filter: props.filter,
      pluckLabel: props.pluckLabel,
      pluckValue: props.pluckValue,
      onSelect: props.onSelect
    };

    const InputProps = {
      styles: props.inputStyles,
      DropdownIndicator: props.DropdownIndicator,
      loadingIndicatorProps: props.loadingIndicatorProps,
      ClearIndicator: props.ClearIndicator,
      Value: props.Value,
      name: props.id || props.name,
      placeholder: props.placeholder,
      isClearable: props.isClearable,
      autoFocus: props.autoFocus,
      required: props.required,
      disabled: props.disabled,
      // NOTE: Disabled because we don't want forms handling them - we want Select handling them.
      // onBlur: props.onBlur,
      // onFocus: props.onFocus,
      // onChange: props.onChange,
      Container: 'div',
      onChange: props.onInputChange,
      onKeyDown: props.onKeyDown,
      autoBlur: props.autoBlur,
      isSearchable: props.isSearchable,
      shouldOpenOnFocus: props.shouldOpenOnFocus,
      shouldBackspaceRemove: props.shouldBackspaceRemove,
      shouldDeleteRemove: props.shouldDeleteRemove,
      shouldEscapeClearValue: props.shouldEscapeClearValue,
      shouldTabSelectValue: props.shouldTabSelectValue,
      shouldBlurResetInput: props.shouldBlurResetInput,
      shouldCloseResetInput: props.shouldCloseResetInput,
      shouldCloseOnSelect: props.shouldCloseOnSelect,
      shouldMenuOpen: props.shouldMenuOpen,
      onCleared: props.onCleared,
      readOnly: props.readOnly
    };

    // https://github.com/erikras/redux-form/blob/master/src/propTypes.js
    // Note: We don't want the other prop's, as out Select accounts for most cases.
    const FieldProps = {
      meta: props.meta
    };

    const MenuProps = {
      styles: props.menuStyles,
      Option: props.Option,
      OptionNotFound: props.isLoading
        ? props.OptionLoading
        : props.OptionNotFound,
      OptionSelected: props.OptionSelected,
      onOpen: props.onOpen,
      onClose: props.onClose,
      onScrollToBottom: props.onScrollToBottom,
      onOptionHover: props.onOptionHover,
      onOptionSelect: props.onOptionSelect,
      shouldCloseOnSelect: props.shouldCloseOnSelect,
      shouldSelectResetInput: props.shouldSelectResetInput
    };

    const options = props.isLoading
      ? []
      : props.fixtures
      ? (props.options || []).concat(
          props.fixtures.map((fixture) => ({
            ...fixture,
            fixturePrefillData: props.fixturePrefillData,
            isFixture: true,
            value: fixture.value || fixture.label
          }))
        )
      : props.options || [];

    return (
      <VividSelect
        {...SelectProps}
        options={options}
        onSelect={this.handleSelect}
        selected={this.state.selected}
      >
        <SelectInput
          {...InputProps}
          {...FieldProps}
          data-lpignore='true'
          onBlur={this.handleBlur}
        />
        <div style={{ position: 'static', width: '100%' }}>
          <div style={{ position: 'relative' }}>
            <VividSelectMenu {...MenuProps} />
          </div>
        </div>
      </VividSelect>
    );
  }
}

export default Select;
