import React, { Component, Fragment } from 'react';
import Box from '@rexlabs/box';
import { PADDINGS } from 'src/theme';
import Heading from 'shared/components/text/heading';
import Body from 'shared/components/text/body';

class AuthView extends Component {
  render() {
    const { heading, body, children } = this.props;
    return (
      <Fragment>
        <Box width='100%' mt={PADDINGS.XL}>
          <Heading level={1}>{heading}</Heading>
        </Box>
        <Box width='100%' mt={PADDINGS.M} mb={PADDINGS.XL}>
          <Body darkergrey normal regular large>
            {body}
          </Body>
        </Box>
        {children}
      </Fragment>
    );
  }
}

export default AuthView;
