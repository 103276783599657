import React, { Component } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { OptionLabel, OptionDetail } from 'auth-app/src/view/components/text';
import { COLORS } from 'auth-app/src/theme';
import _ from 'lodash';

import Option from './core';

const defaultStyles = StyleSheet({
  container: {
    cursor: 'pointer'
  },
  isActive: { color: `${COLORS.DARK_GREY} !important` },
  isSelected: { color: COLORS.LOGIN.STATES.ACTIVE },
  isPassive: { color: COLORS.LOGIN.STATES.IDLE },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
    width: '24px',
    fill: COLORS.SLATE,
    color: COLORS.SLATE
  }
});

@styled(defaultStyles)
class DescriptiveOption extends Component {
  static defaultProps = {
    isPassive: false,
    onHover: _.noop,
    onSelect: _.noop,
    pluckStatus: (option) => _.get(option, 'description')
  };

  render() {
    const {
      styles: s,
      option,
      pluckLabel,
      pluckStatus,
      isActive,
      isPassive,
      isSelected,
      Icon
    } = this.props;

    const isFixture = _.get(option, 'isFixture');
    const Fixture = _.get(option, 'model.select.Fixture');

    const description = [pluckStatus ? pluckStatus(option) : null].filter(
      Boolean
    );

    return isFixture ? (
      <Fixture {...this.props} />
    ) : (
      <Option {...this.props}>
        <Box {...s('container')} flex={1} p={10}>
          {Icon && (
            <Box>
              <Icon {...s('icon')} />
            </Box>
          )}
          <Box flexDirection='column'>
            <OptionLabel
              {...s('container', {
                isActive: isActive && !isPassive,
                isSelected: isSelected && !isPassive,
                isPassive
              })}
            >
              {pluckLabel(option)}
            </OptionLabel>
            <OptionDetail>{description}</OptionDetail>
          </Box>
        </Box>
      </Option>
    );
  }
}

export default DescriptiveOption;
