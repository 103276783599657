// Copied from Spoke
import React, { PureComponent } from 'react';
import { StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Field } from '@rexlabs/form';
import { COLORS, PADDINGS, TEXTS } from 'shared/theme';
import _ from 'lodash';

const fieldStyles = StyleSheet({
  container: {
    borderColor: COLORS.GREY,
    flexShrink: 0
  },

  containerError: {
    borderColor: COLORS.PRIMARY.RED
  },

  field: {
    position: 'relative',
    marginTop: PADDINGS.S,

    '&:first-child': {
      marginTop: 0
    }
  },

  fieldLabel: {
    ...TEXTS.FIELD.LABEL
  },
  error: {
    marginTop: PADDINGS['3XS'],
    ...TEXTS.FIELD.ERROR
  }
});

const createFieldLabel = _.memoize(
  (disabled) =>
    ({ children, ...labelProps }) =>
      children ? (
        <Box
          {...labelProps}
          onClick={disabled ? undefined : labelProps.onClick}
        >
          {children}
        </Box>
      ) : null
);

// TODO: discuss helpText
class FormField extends PureComponent {
  render() {
    const { helpText, subLabel, isInlineLabel, disabled, ...rest } = this.props;
    return (
      <Field
        key={rest.name}
        styles={fieldStyles}
        isInlineLabel={isInlineLabel}
        {...rest}
        FieldLabel={createFieldLabel(disabled)}
      />
    );
  }
}

export default FormField;
