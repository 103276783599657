import React, { Component } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Body } from 'view/components/text';
import { ICONS } from 'shared/components/icon';
import { COLORS, TEXTS, PADDINGS } from 'src/theme';

const { CLOSE_MEDIUM: CloseIcon } = ICONS;

const defaultStyles = StyleSheet({
  container: {
    height: '50px',
    width: '100%',
    border: `1px solid ${COLORS.GREY}`,
    borderRadius: '25px'
  },
  avatar: {
    height: '40px',
    width: '40px',
    borderRadius: '100%',
    background: COLORS.BLUE,
    ...TEXTS.LOGIN.AVATAR
  },
  noAvatar: {
    width: '2rem'
  },

  email: {
    overflow: 'hidden'
  },
  iconContainer: {
    cursor: 'pointer'
  },
  icon: {
    height: '18px',
    width: '18px',
    color: COLORS.SLATE_DARK
  }
});

@styled(defaultStyles)
class EmailFixture extends Component {
  render() {
    const { styles: s, email, initials, onCloseClick, isPocket } = this.props;

    return (
      <Box
        {...s('container')}
        alignItems='center'
        justifyContent='space-between'
      >
        {isPocket ? (
          <Box {...s('noAvatar')} />
        ) : (
          <Box
            {...s('avatar')}
            alignItems='center'
            justifyContent='center'
            m={PADDINGS.XXS}
            mr={PADDINGS.XS}
          >
            {initials.toUpperCase()}
          </Box>
        )}
        <Box {...s('email')} flex={1}>
          <Body
            darkergrey
            regular
            normal
            large
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {email}
          </Body>
        </Box>
        <Box
          {...s('iconContainer')}
          alignItems='center'
          justifyContent='center'
          m={PADDINGS.XS}
        >
          <CloseIcon {...s('icon')} onClick={onCloseClick} />
        </Box>
      </Box>
    );
  }
}

export default EmailFixture;
