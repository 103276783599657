import React, { PureComponent } from 'react';
import Button from '@rexlabs/button';
import { StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'shared/theme';
import _ from 'lodash';

const getStyles = _.memoize(
  (white, grey, large) =>
    StyleSheet({
      container: {
        background: 'transparent',
        color: white ? COLORS.WHITE : grey ? COLORS.GREY : COLORS.BLUE,
        height: large ? '44px' : '36px',
        padding: '0 12px',
        border: '0 none',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '14px',
        fontWeight: 600,
        textDecoration: 'underline',
        fontFamily: '"proxima-nova", helvetica, arial, sans-serif'
      }
    }),
  (white, grey, large) => `${white}-${grey}-${large}`
);

class TextButton extends PureComponent {
  render() {
    const { white, grey, large } = this.props;

    return (
      <Button
        isInverted
        styles={getStyles(white, grey, large)}
        type={this.props.form ? 'submit' : 'button'}
        {...this.props}
      />
    );
  }
}

export default TextButton;
