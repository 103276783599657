/*
  NOTE: for more context, ATM we are using Segment (https://segment.com/docs/sources/website/analytics.js/)
  with the 'destinations': FullStory, Google Analytics and Intercom. We also download a Bugsnag client ourselves
  (v6) because Segment's version (v2) doesn't support setting a beforeSend after the client initialisation. We make
  direct calls to the bugsnagClient's notify where it makes sense below and because we have intertwined logic that
  allows us to send Bugsnag reports with a FullStory timestamp and also sends a FullStory event with a
  link to the related Bugsnag error report, we often don't include FullStory in the Segment track call so
  that we can avoid doubling up events to FullStory.
*/

import { TYPES } from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';
import config from 'shared/utils/config';
import _ from 'lodash';
import env from '../config';

const INTERCOM_DEFAULT_NO_CHAT = {
  Intercom: {
    hideDefaultLauncher: true
  }
};

// The performanceEvents object allows us to track the start and end of task in 1 place, this is especially helpful when it's start and end is located in vastly different areas of the app
const performanceEvents = {};
// Holds all pending network requests, this allows us to fire requests then wait for the response before continuing to see network time impact on a task
const pendingRequests = {};

// shellInstance allows us to corrolate better between users -> pages -> events.
// Also the `shellInstance-` prefix just makes it clear what these values are representing later when we flatten the object for mixpanel
const shellInstance = {
  'shellInstance-initialTime': new Date().getTime(),
  'shellInstance-pagesSinceInit': 0
};

// Small util to format the task's sub task keys (just adds more meta information) - note mixpanel is constrained by object depth
const getSubTaskName = (task, event) => {
  return `subTask-${_.kebabCase(event)}`;
};

function analyticsMiddleware(action = {}) {
  const [typeBase, type] = action.type.split('/');

  if (typeBase !== 'VIVID_ANALYTICS') {
    return;
  }

  // Keys have been setup for Intercom as it has specific ones hardcoded
  // The custom keys we have added are in title case
  const userToSegment = ({ user, office, alphaEnabled, parentAccountIds }) => {
    return {
      userId: _.get(user, 'id', ''),
      name: _.get(user, 'full_name', ''),
      email: _.get(user, 'email', ''),
      avatar:
        (_.get(user, 'profile_image.url') &&
          `https:${_.get(user, 'profile_image.url')}`) ||
        '',
      // 'Signed up': 'TODO', // todo
      phone: _.get(user, 'settings.phone_mobile', ''),
      title: _.get(user, 'settings.position', ''),

      role: _.get(user, 'segmentation_role.text', ''),
      techSkill: _.get(user, 'segmentation_tech_skill', ''),
      residentialTransactions: !!_.get(
        user,
        'segmentation_transactions_residential',
        ''
      ),
      commercialTransactions: !!_.get(
        user,
        'segmentation_transactions_commercial',
        ''
      ),

      company: {
        // Office
        id: _.get(office, 'id', ''),
        name: _.get(office, 'name', ''),
        // 'Company created at': 'TODO', // todo
        Region: _.get(office, 'country_region_id', ''),
        'Key Contact': _.get(office, 'settings.key_contact_name', ''),
        'Alpha Enabled': alphaEnabled
      },

      // These are redundant as they are included in the company, but it helps for top level filtering in Mixpanel
      accountName: _.get(office, 'name', ''),
      accountId: _.get(office, 'id', ''),

      // Adding location information to most (Intercom can process objects as user traits, but 'Region' doesn't get send to most services such as UserLeap)
      country: _.get(office, 'locale.country', ''),
      countryCode: _.get(office, 'locale.code_alpha2', ''),
      countryRegion: _.get(office, 'locale.country_region', ''),
      'Key Contact': _.get(office, 'settings.key_contact_name', ''),
      'Alpha Enabled': alphaEnabled,
      parentAccountIds
    };
  };

  const {
    payload: {
      event,
      userId,
      message,
      error,
      page,
      properties = {},
      options = {},
      callback = _.noop,

      // Performance timing variables
      task,
      hasCompletedTask,
      waitingForRequest,
      requestComplete,
      taskOriginTime
    }
  } = action;

  switch (type) {
    case TYPES.IDENTIFY: {
      const { app, user, office, alphaEnabled } = properties;
      const updatedOptions = { ...INTERCOM_DEFAULT_NO_CHAT, ...options };

      if (window.analytics) {
        window.analytics.identify(
          userId,
          userToSegment(properties),
          updatedOptions
        );

        // TODO: investigate mixpanel groupings, atm I'm not sure its working properly https://app.clubhouse.io/rexlabs/story/51913/investigate-mixpanel-groupings-atm-i-m-not-sure-its-working-properly
        // Grouping by company on segment https://segment.com/docs/connections/spec/group/
        window.analytics.group(office.id, {
          name: _.get(office, 'name'),
          country: _.get(office, 'locale.country'),
          state: _.get(office, 'locale.country_region')
        });
      }

      if (window.bugsnagClient) {
        window.bugsnagClient.user = user;
        window.bugsnagClient.metaData = {
          app: app || 'rex',
          officeDetails: office,
          alphaEnabled: alphaEnabled
        };
      }

      if (window.flagsmith) {
        window.flagsmith.identify(_.get(user, 'id'));
        window.flagsmith.setTraits(userToSegment(properties));
      }

      window.$fvIdentity = {
        id: userId,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        disableReplaysForUser: false,
        env: 'crm-' + (env.ENVIRONMENT?.toLowerCase() || process.env.NODE_ENV),
        roles: [user?.segmentation_role?.text],
        authServiceUserId: user?.auth_service_user_id
      };

      break;
    }

    case TYPES.CUSTOM:
      // FullStory is not hit because we send it via the Bugsnag beforeSend
      window.analytics &&
        window.analytics.track(
          event,
          properties,
          {
            ...options,
            ...{
              integrations: {
                FullStory: false,
                // By default we don't send events to Intercom because you can only track 120 separate events in Intercom
                // But if you have a reason to send to intercom feel free - just check we don't exceed the limit.
                Intercom: false,
                ...options.integrations
              }
            }
          },
          callback
        );

      window.bugsnagClient &&
        window.bugsnagClient.notify(event, {
          severity: 'info',
          ...properties
        });
      break;

    case TYPES.INFORMATION:
      // FullStory is not hit because we send it via the Bugsnag beforeSend
      window.analytics &&
        window.analytics.track(
          EVENTS.INFORMATION,
          { Message: message, ...properties },
          {
            integrations: { FullStory: false }
          }
        );

      window.bugsnagClient &&
        window.bugsnagClient.notify(`Info - ${message}`, {
          severity: 'info',
          ...properties
        });
      break;

    case TYPES.TRACK:
      window.analytics &&
        window.analytics.track(event, properties, {
          integrations: {
            // By default we don't send events to Intercom because you can only track 120 separate events in Intercom
            // But if you have a reason to send to intercom feel free - just check we don't exceed the limit.
            Intercom: false,
            ...options.integrations
          }
        });

      window.bugsnagClient &&
        window.bugsnagClient.leaveBreadcrumb(event, properties);
      break;

    case TYPES.ERROR:
      // Avoiding sending network errors to analytics services
      // (reduce error noise)
      if (error?.problem === 'NETWORK_ERROR') {
        break;
      }

      // FullStory is not hit because we send it via the Bugsnag beforeSend
      window.analytics &&
        window.analytics.track(
          EVENTS.ERROR,
          { Error: _.get(error, 'message', error), ...properties },
          { integrations: { FullStory: false } }
        );

      window.ga &&
        window.ga('send', 'exception', {
          exDescription: _.get(error, 'message', error),
          exFatal: _.get(error, 'message', error).includes('CATASTROPHIC: ')
        });
      window.bugsnagClient &&
        window.bugsnagClient.notify(error, { ...options, ...properties });

      console?.error(error);
      break;

    case TYPES.PERFORMANCE: {
      const memory = window.performance.memory
        ? {
            'mem-jsHeapSizeLimit': window.performance.memory.jsHeapSizeLimit,
            'mem-usedJSHeapSize': window.performance.memory.usedJSHeapSize,
            'mem-totalJSHeapSize': window.performance.memory.totalJSHeapSize
          }
        : {};

      if (task) {
        if (!performanceEvents[task]) {
          // Save new origin time stamp for a task
          performanceEvents[task] = {
            'task-originTime': taskOriginTime || window.performance.now(),
            subTasks: {}, // These hold all the sub tasks
            ...(config.RELEASE && config.RELEASE) // Allows us to track timing differences between app versions
            // isDev: global.__DEV__,
            // ...(global.__DEV__ && {
            //   devVersion: `${
            //     new Date(Date.now()).toLocaleString().split(',')[0]
            //   } - 0.001` // To helping debug by confirming changes are in effect
            // })
          };
        }

        // Allow `properties` to be added to the base 'task' object at any point during the task
        performanceEvents[task] = {
          ...performanceEvents[task],
          ...properties
        };

        if (event) {
          const taskDurations = Object.values(performanceEvents[task].subTasks);
          // Get the time since the last timing, which represents the current sub task duration
          const timeSinceLastTiming =
            window.performance.now() -
            (taskDurations.reduce((prev, cur) => prev + cur, 0) +
              performanceEvents[task]['task-originTime']);

          performanceEvents[task].subTasks[
            getSubTaskName(task, event, performanceEvents[task].subTasks)
          ] = timeSinceLastTiming;

          // PERF-TODO: Bugsnag breadcrubs on perf event to just add more context to other logging https://app.clubhouse.io/rexlabs/story/51914/bugsnag-breadcrubs-on-perf-event-to-just-add-more-context-to-other-logging
          // window.bugsnagClient &&
          //   window.bugsnagClient.leaveBreadcrumb('performance event', {
          //     [event]: timeSinceTaskOrgin
          //   });
        }

        if (hasCompletedTask) {
          // Log perf info for task
          // Flattening the task event (for mixpanel), just makes for more granular displaying as it only allows 2/3 layers object depth filtering of the event objects :S
          const flatEvent = {
            ...performanceEvents[task],
            ...performanceEvents[task].subTasks,
            'task-totalTime':
              window.performance.now() -
              performanceEvents[task]['task-originTime'],
            ...shellInstance,
            ...memory
          };
          delete flatEvent.subTasks;

          window.analytics &&
            window.analytics.track(
              `${EVENTS.PERFORMANCE}-Task-${task}`,
              flatEvent,
              {
                integrations: {
                  Mixpanel: false,
                  FullStory: false // PERF-TODO: check this but I think this subset of events is a fair bit less spammy so we can probably include FullStory https://app.clubhouse.io/rexlabs/story/51915/check-this-but-i-think-this-subset-of-events-is-a-fair-bit-less-spammy-so-we-can-probably-include-fullstory
                }
              }
            );

          // PERF-TODO: Log to bugsnag and test this https://app.clubhouse.io/rexlabs/story/51916/log-to-bugsnag-and-test-this
          // window.bugsnagClient &&
          //   window.bugsnagClient.notify(`${EVENTS.PERFORMANCE} - ${task}`, {
          //     severity: 'info',
          //     metaData: {
          //       [task]: performanceEvents[task],
          //       shellInstance
          //     }
          //   });

          // Remove the task from perf logging object after it's been completed and logged
          delete performanceEvents[task];
        }
      } else {
        const eventName = event
          ? `${EVENTS.PERFORMANCE}-${event}`
          : EVENTS.PERFORMANCE;

        window.analytics &&
          window.analytics.track(
            eventName,
            {
              ...properties,
              ...memory
            },
            {
              integrations: {
                Mixpanel: false,
                FullStory: false
              }
            }
          );

        window.bugsnagClient &&
          Object.keys(memory).length > 0 &&
          window.bugsnagClient.leaveBreadcrumb('memory', {
            metaData: { memory }
          });
      }

      // Note: these 2 if cases contain the logic to 'wait for specific requests to finish' and
      // 'catch a signal that a specific request has finished'
      if (waitingForRequest) {
        const { requestId, onRequestComplete } = waitingForRequest;
        pendingRequests[requestId] = onRequestComplete;
      }
      if (requestComplete) {
        if (_.isFunction(pendingRequests[requestComplete])) {
          pendingRequests[requestComplete]();
          delete pendingRequests[requestComplete];
        }
      }
      break;
    }

    case TYPES.NAVIGATION:
      // Tracking how many pages we have navigated since initialising 'shell'
      shellInstance['shellInstance-pagesSinceInit']++;

      // Intercom is not hit because we need to trigger an 'update' directly otherwise they don't update the recent pages
      window.analytics &&
        window.analytics.page(page, properties, {
          integrations: { Intercom: false }
        });

      window.Intercom &&
        window.Intercom('update', {
          last_request_at: parseInt(new Date().getTime() / 1000)
        });

      window.bugsnagClient &&
        window.bugsnagClient.leaveBreadcrumb('navigation', {
          metaData: { page, properties, shellInstance }
        });
      break;
  }
}

export default analyticsMiddleware;
