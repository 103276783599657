import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { TextInput } from '@rexlabs/text-input';
import Box from '@rexlabs/box';
import { Link } from '@rexlabs/whereabouts';
import { createValidationRules } from '@rexlabs/validator';
import AuthContext from 'view/containers/auth-context';
import { PADDINGS, FONT, BREAKPOINTS, COLORS } from 'src/theme';
import ROUTES from 'src/routes';

// Shared
import config from 'shared/utils/config';
import AuthView from 'shared/components/auth-view';
import PasswordInput from 'shared/components/input/password';
import { DefaultButton, LoadingButton } from 'shared/components/button';
import { ReactForms, Form, FormField } from 'shared/components/form';
import Spinner from 'shared/components/spinner';

import _ from 'lodash';

const validateResetPassword = createValidationRules({
  email: 'required|email'
});

const validateChangePassword = createValidationRules({
  new_password: 'required',
  confirm_password: 'required|same:new_password'
});

const fieldOverrides = {
  InnerField: StyleSheet({
    field: {
      '&&': {
        marginTop: '0'
      }
    }
  })
};

const defaultStyles = StyleSheet({
  responsiveLabel: {
    [BREAKPOINTS.MOBILE]: {
      fontSize: '14px',
      marginBottom: '0.8rem'
    },
    color: COLORS.GREY,
    fontSize: '16px',
    lineHeight: '16px',
    marginBottom: '1rem'
  },
  button: {
    [BREAKPOINTS.MOBILE]: {
      width: '100%'
    }
  }
});

@styled(defaultStyles)
@autobind
class ResetPassword extends Component {
  static contextType = AuthContext;

  /**
   * If you switch the AUTH_DEV_MODE variable to true in shared/env.js
   * Then you can easily test this page without needing a valid action token.
   *
   * You can open the below route to see the password input fields:
   *    /actions/password_reset/#action_token
   */

  isDevMode = config.AUTH_DEV_MODE;

  state = {
    isLoading: false,
    actionDetails: null,
    actionTokenExpired: false
  };

  componentDidMount() {
    const { setResetPasswordSuccess, getActionDetails } = this.context;
    const {
      whereabouts: { hashQuery }
    } = this.props;

    setResetPasswordSuccess(false);

    const actionToken = _.get(hashQuery, 'action_token');

    if (actionToken && !this.isDevMode) {
      this.setState(
        () => ({
          isLoading: true
        }),
        () => {
          getActionDetails(actionToken)
            .then((response) => {
              this.setState(() => ({
                isLoading: false,
                actionDetails: response.data.result
              }));
            })
            .catch(() => {
              this.setState(() => ({
                isLoading: false,
                actionTokenExpired: true
              }));
            });
        }
      );
    }

    if (this.isDevMode) {
      this.setState({
        isLoading: false,
        actionDetails: {
          meta: {
            email: 'developer@rexsoftware.com.au'
          }
        }
      });
    }
  }

  preventDefault(e) {
    e.preventDefault();
  }

  render() {
    const {
      resetPasswordSuccess,
      handleResetPasswordSubmit,
      handleChangePasswordSubmit,
      app,
      loginInfo
    } = this.context;
    const { isLoading, actionDetails, actionTokenExpired } = this.state;
    const {
      whereabouts: { hashQuery },
      styles: s
    } = this.props;

    const actionToken = _.get(hashQuery, 'action_token');
    const prefillValues = {
      email: loginInfo.email
    };

    return (
      <Box>
        {!actionToken ? (
          <ReactForms
            validateOnMount
            touchOnMount={false}
            touchOnChange={false}
            validate={validateResetPassword}
            handleSubmit={this.isDevMode ? _.noop : handleResetPasswordSubmit}
            initialValues={prefillValues}
          >
            {({ values: { email }, isSubmitting, isValid, submitForm }) => {
              return (
                <AuthView
                  heading={
                    !resetPasswordSuccess
                      ? 'Forgot your password?'
                      : 'Check your inbox'
                  }
                  body={
                    !resetPasswordSuccess ? (
                      'Enter your Rex account email address below and we’ll send you instructions to reset it.'
                    ) : (
                      <span>
                        Instructions for resetting your password have been sent
                        to{' '}
                        <span style={{ fontWeight: FONT.WEIGHTS.SEMIBOLD }}>
                          {email}
                        </span>
                        . You’ll receive this email within 10-15 minutes. Make
                        sure to check your spam folder, too.
                      </span>
                    )
                  }
                >
                  {!resetPasswordSuccess ? (
                    <Form name='reset_password' showErrors={false}>
                      <div {...s('responsiveLabel')}>email</div>
                      <StylesProvider components={fieldOverrides}>
                        <FormField
                          sendImmediate
                          shouldUnregister={false}
                          name='email'
                          Input={TextInput}
                          inputProps={{
                            placeholder: 'rexuser@email.com'
                          }}
                          onBlur={(e) => {
                            if (window.bugsnagClient) {
                              window.bugsnagClient.user = {
                                email: e.target.value
                              };
                            }
                          }}
                        />
                      </StylesProvider>
                      <Box
                        justifyContent='flex-end'
                        width='100%'
                        mt={PADDINGS.XL}
                      >
                        <LoadingButton
                          {...s('button')}
                          softblue={app !== 'pocket'}
                          pocket={app === 'pocket'}
                          large
                          form='reset_password'
                          isLoading={isSubmitting}
                          isDisabled={!isValid}
                          onClick={submitForm}
                        >
                          Send instructions
                        </LoadingButton>
                      </Box>
                    </Form>
                  ) : (
                    <Box
                      alignItems='center'
                      justifyContent='flex-end'
                      width='100%'
                      mt={PADDINGS.XL}
                    >
                      <Link to={ROUTES.LOGIN} query={{ app_id: app }}>
                        {({ onClick }) => (
                          <DefaultButton
                            {...s('button')}
                            softblue={app !== 'pocket'}
                            pocket={app === 'pocket'}
                            large
                            onClick={onClick}
                          >
                            Return to login
                          </DefaultButton>
                        )}
                      </Link>
                    </Box>
                  )}
                </AuthView>
              );
            }}
          </ReactForms>
        ) : isLoading ? (
          <Box justifyContent={'center'}>
            <Spinner dark />
          </Box>
        ) : actionTokenExpired ? (
          <AuthView heading='This password reset link has expired'>
            <Box justifyContent='flex-end' mt={PADDINGS.XL}>
              <Link to={ROUTES.RESET_PASSWORD} query={{ app_id: app }}>
                {({ onClick }) => (
                  <DefaultButton
                    {...s('button')}
                    softblue={app !== 'pocket'}
                    pocket={app === 'pocket'}
                    large
                    onClick={onClick}
                  >
                    Get another link
                  </DefaultButton>
                )}
              </Link>
            </Box>
          </AuthView>
        ) : (
          <ReactForms
            validateOnMount
            touchOnMount={false}
            touchOnChange={false}
            validate={(values, { meta }) =>
              validateChangePassword({
                // Hack to get around a current
                // ReactForms limitation
                new_password: _.get(meta, 'newPassword'),
                ...values
              })
            }
            handleSubmit={handleChangePasswordSubmit}
          >
            {({ isSubmitting, isValid, meta, setMeta, submitForm }) => (
              <AuthView
                heading='Create a new password'
                body={
                  <span>
                    For{' '}
                    <span style={{ fontWeight: FONT.WEIGHTS.SEMIBOLD }}>
                      {_.get(actionDetails, 'meta.email', 'unknown')}
                    </span>
                  </span>
                }
              >
                <Form
                  name='change_password'
                  showErrors={false}
                  onSubmit={this.preventDefault}
                >
                  <StylesProvider components={fieldOverrides}>
                    <Box {...s('responsiveLabel')}>new password</Box>
                    <FormField
                      sendImmediate
                      name='new_password'
                      Input={PasswordInput}
                      onChange={(e) => {
                        setMeta({
                          ...meta,
                          newPassword: e.target.value
                        });
                      }}
                    />
                    <Box {...s('responsiveLabel')} mt='3rem'>
                      confirm new password
                    </Box>
                    <FormField
                      sendImmediate
                      name='confirm_password'
                      Input={PasswordInput}
                    />
                  </StylesProvider>
                  <Box justifyContent='flex-end' width='100%' mt={PADDINGS.XL}>
                    <LoadingButton
                      {...s('button')}
                      softblue={app !== 'pocket'}
                      pocket={app === 'pocket'}
                      large
                      form='change_password'
                      isLoading={isSubmitting}
                      isDisabled={!isValid}
                      onClick={() => {
                        setMeta({
                          ...meta,
                          email: _.get(actionDetails, 'meta.email'),
                          actionToken
                        }).then(submitForm);
                      }}
                    >
                      Save password and log in
                    </LoadingButton>
                  </Box>
                </Form>
              </AuthView>
            )}
          </ReactForms>
        )}
      </Box>
    );
  }
}

export default ResetPassword;
