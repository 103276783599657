import React, { PureComponent } from 'react';
import Button from '@rexlabs/button';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'src/theme';
import _ from 'lodash';

const getStyles = _.memoize(
  (blue, red, fullWidth, hasIconLeft, hasIconRight) =>
    StyleSheet({
      container: {
        background: blue
          ? COLORS.BLUE || COLORS.PRIMARY.BLUE
          : red
          ? COLORS.RED || COLORS.PRIMARY.RED
          : COLORS.GREY_LIGHT,
        height: '36px',
        width: fullWidth ? '100%' : 'auto',
        paddingLeft: hasIconLeft ? '8px' : '12px',
        paddingRight: hasIconRight ? '8px' : '12px',
        border: '0 none',
        textAlign: 'center',
        display: 'flex',
        textDecoration: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: '"proxima-nova", helvetica, arial, sans-serif'
      },

      content: {
        color: blue || red ? COLORS.WHITE : COLORS.PRIMARY_DARK,

        '> span': {
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center'
        }
      }
    }),
  (blue, red, fullWidth, hasIconLeft, hasIconRight) =>
    `${blue}-${red}-${fullWidth}-${hasIconLeft}-${hasIconRight}`
);

const defaultStyles = StyleSheet({
  icon: {
    height: '20px',
    width: 'auto'
  },

  iconLeft: {
    marginRight: '4px'
  },

  iconRight: {
    marginLeft: '4px'
  }
});

@styled(defaultStyles)
class PrimaryButton extends PureComponent {
  render() {
    const {
      blue,
      red,
      fullWidth,
      children,
      IconLeft,
      IconRight,
      styles: s,
      ...props
    } = this.props;
    return (
      <Button
        styles={getStyles(blue, red, fullWidth, !!IconLeft, !!IconRight)}
        type={props.form ? 'submit' : 'button'}
        {...props}
      >
        {IconLeft && <IconLeft {...s('icon', 'iconLeft')} />}
        <span>{children}</span>
        {IconRight && <IconRight {...s('icon', 'iconRight')} />}
      </Button>
    );
  }
}

export default PrimaryButton;
