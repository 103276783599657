import React from 'react';

import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { useDownshiftContext } from '@rexlabs/core-select';

import { TEXTS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    ...TEXTS.INPUT.PLACEHOLDER
  }
});

function OptionNotFound({ styles: s }) {
  const { inputValue } = useDownshiftContext();

  return (
    <Box {...s('container')} padding='7px'>
      {inputValue ? `No Results found for ${inputValue}` : 'No results'}
    </Box>
  );
}

export default styled(defaultStyles)(OptionNotFound);
