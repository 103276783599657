/* eslint-disable no-case-declarations */
import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';

import { encode } from 'base64-url';
import {
  match,
  withWhereabouts,
  Link,
  RenderMatchedRoutes,
  parseUrlToRoute,
  parseRouteToUrl,
  push,
  replace
} from '@rexlabs/whereabouts';
import { styled, StyleSheet } from '@rexlabs/styling';
import { withModel } from '@rexlabs/model-generator';
import Box from '@rexlabs/box';
import {
  Body,
  Link as TextLink,
  FooterLink as FooterTextLink,
  Footer
} from 'view/components/text';
import AuthContext from 'view/containers/auth-context';
import ROUTES from 'src/routes';
import { COLORS, PADDINGS, BREAKPOINTS } from 'src/theme';
import { setBaseUrl } from 'shared/utils/api-client';

// Shared
import AuthView from 'shared/components/auth-view';
import ErrorBoundary from 'shared/components/error-boundary';
import { DefaultButton } from 'shared/components/button';
import Spinner from 'shared/components/spinner';
import { ICONS } from 'shared/components/icon';
import authModel from 'shared/data/models/custom/auth';
import config from 'shared/utils/config';
import BlobBlue from 'src/assets/blob-login-blue.svg';
import BlobYellow from 'src/assets/blob-login-yellow.svg';
// Tried to get an SVG for below from design, but one not available - Looks like they've used a fill of the pattern on a shape in PS.
// We would need to make a new svg with a pattern background, which is not worth the time at this stage.
import BlobDotted from 'src/assets/blob-login-dotted.png';
import _ from 'lodash';

const { Provider: AuthProvider } = AuthContext;

const {
  POCKET_LOGO: PocketLogo,
  CLOUD: CloudIcon,
  PAPER_PLANE: PaperPlaneIcon,
  WARNING: WarningIcon,
  CLOSE_MEDIUM_THIN: CloseIcon,
  REX_LOGO: RexLogo
} = ICONS;

const defaultStyles = StyleSheet({
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    background: COLORS.SAND
  },

  containerPocket: {
    background: COLORS.LOGIN.POCKET_BLUE
  },

  containerPopper: {
    backgroundColor: COLORS.WHITE
  },

  blobBlue: {
    position: 'absolute',
    left: '21.5rem',
    top: '-11rem',
    width: '49rem',
    height: '44rem',
    color: COLORS.BRANDING.SKY_BLUE
  },

  blobYellow: {
    position: 'fixed',
    bottom: '-79rem',
    left: '-51rem',
    width: '114rem',
    height: '100rem',
    zIndex: 0,
    color: COLORS.BRANDING.SUNSHINE_YELLOW
  },

  blobDotted: {
    position: 'absolute',
    left: '31rem',
    bottom: '9rem',
    width: '34.7rem',
    height: '37.7rem'
  },

  mainWrap: {
    position: 'relative',
    width: '48rem',

    maxWidth: '90%',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },

  main: {
    position: 'relative',
    borderRadius: '0.5rem',
    background: COLORS.WHITE,
    padding: '4rem',
    marginTop: '3rem',
    marginBottom: '3rem',
    boxShadow: `0px 10px 20px 0px ${COLORS.BLACK_15_OPACITY}`,
    [BREAKPOINTS.MOBILE]: {
      padding: '2rem',
      marginTop: '2rem',
      marginBottom: '2rem'
    }
  },

  mainPopper: {
    padding: 0,
    boxShadow: 'none'
  },

  error: {
    position: 'absolute',
    bottom: '100%',
    marginTop: '2rem',
    padding: '1rem',
    borderRadius: '0.5rem',
    background: COLORS.WHITE,
    boxShadow: `0px 10px 20px 0px ${COLORS.BLACK_15_OPACITY}`,
    width: '100%',
    zIndex: 5,
    [BREAKPOINTS.SMALL_MOBILE]: {
      position: 'static'
    }
  },

  warning: {
    color: COLORS.PRIMARY.RED,
    marginRight: PADDINGS.XS
  },

  close: {
    height: '1.8rem',
    width: '1.8rem',
    marginLeft: PADDINGS.XS,
    color: COLORS.SLATE_DARK,
    cursor: 'pointer'
  },

  logo: {
    height: 'auto',
    width: '9rem',
    position: 'relative',
    left: '-4px',
    color: COLORS.BRANDING.REX_BLUE
  },

  logoPocket: {
    width: '4rem',
    height: '4rem'
  },

  content: {
    height: '18rem',
    width: '48rem',
    borderRadius: '0.5rem',
    background: COLORS.WHITE,
    marginBottom: '3rem',
    boxShadow: `0px 10px 20px 0px ${COLORS.BLACK_15_OPACITY}`
  },

  footer: {
    position: 'relative',
    zIndex: 2
  },

  terms: {
    flexWrap: 'wrap',
    minWidth: '360px',
    marginBottom: '1.5rem'
  },

  footerLink: {
    '&:hover': {
      opacity: 0.5
    }
  },

  cloud: {
    position: 'absolute',
    top: '250px',
    left: '-159px',
    [BREAKPOINTS.MOBILE]: {
      visibility: 'hidden'
    }
  },

  paperPlane: {
    position: 'absolute',
    top: '40px',
    right: '-132px',
    [BREAKPOINTS.MOBILE]: {
      visibility: 'hidden'
    }
  }
});

const LoginNotFound = () => {
  // Always redirect to login if route is not found!
  replace(ROUTES.LOGIN);
  return (
    <Box width='100%'>
      <Body medium darkergrey normal regular>
        Route not found, redirecting.
      </Body>
    </Box>
  );
};
const PaddedDots = () => (
  <Box pl={5} pr={5}>
    <Footer> • </Footer>
  </Box>
);

@withWhereabouts
@withModel(authModel)
@styled(defaultStyles)
@autobind
class AuthLayout extends Component {
  state = {
    resetPasswordSuccess: false
  };

  componentDidMount() {
    if (window.navigator.userAgent.match(/Android/i)) {
      window.addEventListener('resize', this.avoidAndroidVirtualKeyboard, true);
    }
  }

  // NOTE: we do error handling in the model itself, so we don't really need
  // to do it here other than for stuff that doesn't use the model actions
  // for async stuff / things that might fail
  handleError(e) {
    this.props.auth.setError({ error: e.message });
  }

  createErrorHandler(fallbackMessage, formatMessage = _.identity) {
    return (e) => {
      const errorMessage = _.get(e, 'message')
        ? formatMessage(_.get(e, 'message'))
        : fallbackMessage;
      this.props.auth.setError({ error: errorMessage });
    };
  }

  /**
   * Android doesn't handle keyboard avoidance nearly as nicely as iOS does.
   * This is a bit of a hack to scroll down when the virtual keyboard is shown,
   * revealing our form buttons
   */
  avoidAndroidVirtualKeyboard() {
    // Ghetto smooth scrolling (better than nothing!)
    const smoothScroll = () => {
      if (window.pageYOffset >= 160) return;
      window.scrollTo(0, window.pageYOffset + 40);
      window.requestAnimationFrame(smoothScroll);
    };
    window.requestAnimationFrame(smoothScroll);
  }

  handleRemoveEmail() {
    const { auth } = this.props;
    auth.clear();
  }

  handleLogin(method, params) {
    const { auth } = this.props;
    return auth
      .login({
        loginMethodId: method,
        params
      })
      .catch(() => {
        // Errors are handled within the action reducers, which is not
        // ideal because by default model generator passes errors through,
        // so this gets logged as uncaught exception to bugsnag if we don't
        // catch it here :/
      });
  }

  handleOtherLoginMethods(method) {
    const { auth } = this.props;

    return auth
      .getLoginFlowUrl({ loginMethodId: _.get(method, 'id') })
      .then((response) => {
        const currentRoute = parseUrlToRoute(window.location.href);

        const redirectRoute = parseUrlToRoute(response.redirectUrl);

        if (redirectRoute.query.state) {
          redirectRoute.query.state = [
            redirectRoute.query.state,
            ..._.map(currentRoute.query, (query, key) =>
              encode(`${key}=${query}`)
            )
          ].join('.');
        }

        // HACK: currently needed because whereabouts strips the origin
        // will see if we can add that behaviour to whereabouts to ensure there's
        // no other breaking changes elsewhere
        // https://app.shortcut.com/rexlabs/story/61230
        const urlObj = new URL(response.redirectUrl);

        const finalUrl =
          `${urlObj.origin}/` +
          `${redirectRoute.path.substring(1)}` +
          `?${_.map(
            redirectRoute.query,
            (query, key) => `${key}=${query}`
          ).join('&')}` +
          `${redirectRoute.hash || ''}`;

        // Useful for debugging purpose when clients encounter problems
        // eslint-disable-next-line
        console.log('Redirecting to external login', finalUrl);

        window.location.href = finalUrl;
      })
      .catch(
        this.createErrorHandler(
          'Redirect to external login failed, please retry.',
          (msg) => `Redirect to external login failed - ${msg}`
        )
      );
  }

  handleAccountLogin(account) {
    const errorHandler = this.createErrorHandler(
      'Redirect to app failed, please retry.',
      (msg) => `Redirect to app failed - ${msg}`
    );
    // Select an Account screen needs a promise for its `Log in` button.
    return (
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async () => {
        const { auth, whereabouts } = this.props;
        const token = auth.loginInfo.token;

        const appId = whereabouts.query.app_id;
        const redirectUri = whereabouts.query.redirect_uri;
        let appUrl = config.REX_APP_URL;

        switch (appId) {
          case 'rexgroup':
            appUrl = config.GROUP_APP_URL;
            break;
          case 'pocket':
            window.postMessage(
              JSON.stringify({
                regionId: account.region.id,
                accountId: account.account_id,
                token
              })
            );
            break;
          case 'popper': {
            if (!global.setAuthToken) {
              // Global method is being injected by electron for the
              // call popper app, so bail if it's not there
              return;
            }

            // We do all this logic here, because we already have it in the auth model,
            // so we don't need to copy/paste it into the popper app as well
            const response = await auth.getRegions();
            const regions = _.get(response, 'data.result');
            const region = _.find(regions, (r) => r.id === account.region.id);

            if (!region) {
              throw new Error('Could not find region base url!');
            }
            setBaseUrl(`${region.base_url}/v1/rex/`);

            // Exchange global token for local API token
            const exchange = await auth.exchangeApiToken({
              token,
              accountId: account.account_id
            });
            const apiToken = _.get(exchange, 'data.result');

            global.setAuthToken({ region, account, token: apiToken });
            return;
          }
        }

        if (redirectUri) {
          const redirectRoute = parseUrlToRoute(redirectUri);
          redirectRoute.query.region_id = account.region.id;
          redirectRoute.query.account_id = account.account_id;
          redirectRoute.hashQuery.token = token;
          redirectRoute.path = redirectRoute.path.replace(`/${appUrl}`, '');
          // NOTE: we're relying on `hashQuery`, so anything in the hash is not
          // needed + if we don't delete it it would take precedent over hashQuery :/
          delete redirectRoute.hash;

          window.location.href = `${appUrl}${parseRouteToUrl(redirectRoute)}`;
        } else {
          window.location.href =
            `${appUrl}?region_id=${account.region.id}` +
            `&account_id=${account.account_id}` +
            `#token=${token}`;
        }
      }).catch((e) => errorHandler(e))
    );
  }

  handleContinueAndPasswordSubmit({
    email,
    password,
    account: { value: account } = {}
  }) {
    const { auth } = this.props;
    const token = auth.loginInfo.token;
    // clear error state before continuing -
    // if there's a problem here, we'll add a new error
    // if there's not, we don't want old errors sticking around
    auth.setError({ error: null });
    if (token && account) {
      return this.handleAccountLogin(account);
    } else if (!password) {
      return auth.getSupportedLoginMethods({ email });
    } else {
      return this.handleLogin('password', { email, password });
    }
  }

  handleChoosePassword() {
    const { auth } = this.props;
    auth.setLoginInfo({
      loginPreference: 'password'
    });
  }

  handleRememberMeChange(e) {
    const { auth, whereabouts } = this.props;
    if (whereabouts.query.app_id === 'pocket') {
      auth.setRemember(true);
    } else {
      auth.setRemember(e.target.checked);
    }
  }

  handleResetPasswordSubmit({ email }) {
    const { auth } = this.props;
    return new Promise((resolve, reject) => {
      auth
        .sendResetPasswordInstructions({ email, application: 'rex' })
        .then(() => {
          this.setResetPasswordSuccess(true);
          resolve();
        })
        .catch((e) => {
          this.createErrorHandler(
            'Reset password request failed, please retry.',
            (msg) => `Reset password request failed - ${msg}`
          )(e);
          reject(e);
        });
    });
  }

  handleChangePasswordSubmit({ confirm_password: password }, { meta }) {
    const { auth } = this.props;

    const currentRoute = parseUrlToRoute(window.location.href);

    return new Promise((resolve, reject) => {
      auth
        .changePassword({
          actionToken: meta.actionToken,
          password,
          app: currentRoute.hashQuery?.app
        })
        .then(() => {
          this.handleContinueAndPasswordSubmit({
            email: _.get(meta, 'email'),
            password
          }).then(() => {
            push(ROUTES.LOGIN);
            resolve();
          });
        })
        .catch((e) => {
          this.createErrorHandler(
            'Change password request failed, please retry.',
            (msg) => `Change password request failed - ${msg}`
          )(e);
          reject(e);
        });
    });
  }

  setResetPasswordSuccess(success) {
    this.setState(() => ({
      resetPasswordSuccess: success
    }));
  }

  getActionDetails(actionToken) {
    const { auth } = this.props;
    return auth.getActionDetails({ actionToken });
  }

  handleRegisterUserSubmit(values, { meta }) {
    const { auth } = this.props;

    const currentRoute = parseUrlToRoute(window.location.href);

    return auth.registerUser({
      actionToken: _.get(meta, 'actionToken'),
      email: _.get(values, 'email'),
      password: _.get(values, 'password'),
      firstName: _.get(values, 'first_name'),
      lastName: _.get(values, 'last_name'),
      segmentationRoleId: _.get(values, 'segmentation_role_id.value'),
      segmentationTechSkill: _.get(values, 'segmentation_tech_skill'),
      segmentationTransactionsResidential: _.get(
        values,
        'segmentation_transactions_residential'
      ),
      segmentationTransactionsCommercial: _.get(
        values,
        'segmentation_transactions_commercial'
      ),
      app: currentRoute.hashQuery?.app
    });
  }

  handleAcceptInvitationClick(actionToken) {
    const { auth } = this.props;

    const currentRoute = parseUrlToRoute(window.location.href);

    return auth.processUserAccessInvite({
      actionToken,
      acceptOrReject: 'accept',
      app: currentRoute.hashQuery?.app
    });
  }

  handleDeclineInvitationClick(actionToken) {
    const { auth } = this.props;
    return auth.processUserAccessInvite({
      actionToken,
      acceptOrReject: 'reject'
    });
  }

  handleGetStartedClick() {
    this.handleRemoveEmail();
    push(ROUTES.LOGIN);
  }

  renderErrorBoundary() {
    const { whereabouts } = this.props;
    return (
      <AuthView heading='Something went wrong'>
        <Box justifyContent='flex-end' mt={PADDINGS.XL}>
          <Link to={ROUTES.LOGIN} query={{ app_id: whereabouts.query.app_id }}>
            {({ onClick }) => (
              <DefaultButton
                softblue={whereabouts.query.app_id !== 'pocket'}
                pocket={whereabouts.query.app_id === 'pocket'}
                large
                onClick={onClick}
              >
                Return to login
              </DefaultButton>
            )}
          </Link>
        </Box>
      </AuthView>
    );
  }

  render() {
    const {
      styles: s,
      whereabouts,
      auth: { meta, loginInfo, lastAccount, setError }
    } = this.props;

    const shouldRenderContent = false;

    const isPasswordReset = match(whereabouts, ROUTES.RESET_PASSWORD.config);

    const isPocket = whereabouts.query.app_id === 'pocket';
    const isPopper = whereabouts.query.app_id === 'popper';
    const isRex =
      whereabouts.query.app_id === 'rex' ||
      whereabouts.query.app_id === 'rexgroup' ||
      !whereabouts.query.app_id;

    return (
      <AuthProvider
        value={{
          meta,
          loginInfo,
          lastAccount,
          setError,
          handleError: this.handleError,

          app: whereabouts.query.app_id || 'rex',
          isValidBrowser: this.isValidBrowser,

          // Login
          handleRemoveEmail: this.handleRemoveEmail,
          handleLogin: this.handleLogin,
          handleAccountLogin: this.handleAccountLogin,
          handleOtherLoginMethods: this.handleOtherLoginMethods,
          handleContinueAndPasswordSubmit: this.handleContinueAndPasswordSubmit,
          handleChoosePassword: this.handleChoosePassword,

          // Reset password
          handleRememberMeChange: this.handleRememberMeChange,
          getActionDetails: this.getActionDetails,
          setResetPasswordSuccess: this.setResetPasswordSuccess,
          resetPasswordSuccess: this.state.resetPasswordSuccess,
          handleResetPasswordSubmit: this.handleResetPasswordSubmit,
          handleChangePasswordSubmit: this.handleChangePasswordSubmit,

          // User invitations
          handleRegisterUserSubmit: this.handleRegisterUserSubmit,
          handleAcceptInvitationClick: this.handleAcceptInvitationClick,
          handleDeclineInvitationClick: this.handleDeclineInvitationClick,
          handleGetStartedClick: this.handleGetStartedClick
        }}
      >
        <Box
          {...s('container', {
            containerPocket: isPocket,
            containerPopper: isPopper
          })}
          flexDirection='column'
          alignItems='center'
        >
          <Box {...s('mainWrap')} flexDirection='column'>
            {isRex && (
              <>
                <BlobYellow {...s('blobYellow')} />
                <img src={BlobDotted} {...s('blobDotted')} />
                <BlobBlue {...s('blobBlue')} />
              </>
            )}
            {meta.error && (
              <Box {...s('error')} flexDirection='row' alignItems='center'>
                <WarningIcon {...s('warning')} />
                <Box flex={1}>
                  <Body normal red medium>
                    {meta.error}
                  </Body>
                </Box>
                <CloseIcon
                  {...s('close')}
                  onClick={() => {
                    setError({ error: '' });
                  }}
                />
              </Box>
            )}
            <Box
              {...s('main', { mainPopper: isPopper })}
              flexDirection='column'
            >
              <Box
                width='100%'
                alignItems='center'
                justifyContent='space-between'
              >
                {isPocket && (
                  <Fragment>
                    <CloudIcon {...s('cloud')} />
                    <PaperPlaneIcon {...s('paperPlane')} />
                  </Fragment>
                )}

                {isPocket ? (
                  <PocketLogo
                    {...s('logo', 'logoPocket')}
                    data-testid='logo-pocket'
                  />
                ) : (
                  <RexLogo {...s('logo')} data-testid='logo-rex' />
                )}
                {isPasswordReset && (
                  <Link
                    to={ROUTES.LOGIN}
                    query={{ app_id: whereabouts.query.app_id }}
                  >
                    {({ onClick, target }) => (
                      <TextLink grey href={target} onClick={onClick}>
                        Return to login
                      </TextLink>
                    )}
                  </Link>
                )}
              </Box>
              <Box>
                <ErrorBoundary Placeholder={this.renderErrorBoundary}>
                  {!meta.ready ? (
                    <Box
                      alignItems='center'
                      justifyContent='center'
                      height='28rem'
                      width='100%'
                      p={PADDINGS.M}
                    >
                      <Spinner large dark />
                    </Box>
                  ) : (
                    <RenderMatchedRoutes
                      routes={ROUTES}
                      NotFound={LoginNotFound}
                    />
                  )}
                </ErrorBoundary>
              </Box>
            </Box>
            {shouldRenderContent && <Box {...s('content')} />}
          </Box>
          {!isPopper && (
            <Box mb='3rem' flexDirection='column' {...s('footer')}>
              <Box alignItems='center' justifyContent='center' {...s('terms')}>
                <Box {...s('footerLink')}>
                  <FooterTextLink
                    href='https://www.rexsoftware.com/legal/api/'
                    target='_blank'
                    rel='noopener noreferrer'
                    white={isPocket}
                  >
                    API Terms
                  </FooterTextLink>
                </Box>
                <PaddedDots />
                <Box {...s('footerLink')}>
                  <FooterTextLink
                    href='https://www.rexsoftware.com/legal/privacy/'
                    target='_blank'
                    rel='noopener noreferrer'
                    white={isPocket}
                  >
                    Privacy
                  </FooterTextLink>
                </Box>
                <PaddedDots />
                <Box {...s('footerLink')}>
                  <FooterTextLink
                    href='https://www.rexsoftware.com/legal/terms/'
                    target='_blank'
                    rel='noopener noreferrer'
                    white={isPocket}
                  >
                    Software Terms
                  </FooterTextLink>
                </Box>
                <PaddedDots />
                <Box {...s('footerLink')}>
                  <FooterTextLink
                    href='https://www.rexsoftware.com/legal/cookie-statement/'
                    target='_blank'
                    rel='noopener noreferrer'
                    white={isPocket}
                  >
                    Cookie Statement
                  </FooterTextLink>
                </Box>
              </Box>
              <Box alignItems='center' justifyContent='center'>
                <Footer white={isPocket}>
                  © Rex Software, all rights reserved
                </Footer>
              </Box>
            </Box>
          )}
        </Box>
      </AuthProvider>
    );
  }
}

export default AuthLayout;
