import React, { Component } from 'react';
import { StyleSheet, styled } from '@rexlabs/styling';
import VividPasswordInput from '@rexlabs/password-input';
import Box from '@rexlabs/box';
import { autobind } from 'core-decorators';
import Toggle from 'shared/components/input/password/toggle';

@styled(
  StyleSheet({
    container: {
      maxWidth: '100%'
    }
  })
)
@autobind
class PasswordInput extends Component {
  render() {
    const { styles: s, StrengthBar, value, ...props } = this.props;
    return (
      <Box {...s('container')}>
        <VividPasswordInput value={value} Icon={Toggle} {...props} />
        {StrengthBar && <StrengthBar password={value || ''} />}
      </Box>
    );
  }
}

export default PasswordInput;
