import _ from 'lodash';

export const PAGE_LIMIT = 20;
export const ETAG_LIMIT = 100;

export function transformList(
  response: any,
  options: { pageLimit?: number } = {}
) {
  // Hacky way to get the offset from the request!
  const params = JSON.parse(response.config.data);
  if (response.data.error) {
    throw new Error(response.data.error?.message || response.data.error);
  }

  const { pageLimit } = options;

  const offset = _.get(params, 'offset', 0);
  const perPage = _.get(params, 'limit', pageLimit || PAGE_LIMIT);

  const rows = _.get(response, 'data.result.rows') || response.data.result;

  const pagination = {
    data: rows.map((r) => ({
      ...r,
      id: r._id || r.id
    })),
    meta: {
      pagination: {
        total: response.data.result.total,
        count: rows.length,
        per_page: perPage,
        current_page: offset ? Math.ceil(offset / perPage) + 1 : 1,
        total_pages: Math.ceil(response.data.result.total / perPage),
        links: []
      }
    }
  };

  return pagination;
}

export function transformListIds(response: any) {
  const rows = _.get(response, 'data.result.rows') || response.data.result;
  return {
    ...response,
    // HACK: model generator uses the `id` field in the response to normalise the data,
    // so we're pretending here to have an item object instead of just returning the flat
    // array of item ids
    data: rows.map((r) => ({ id: r, fromIdsRequest: true })),
    meta: {
      pagination: {
        total: rows.length,
        count: rows.length,
        per_page: 300,
        current_page: 1,
        total_pages: 1,
        links: []
      }
    }
  };
}

export function transformStream(response: any) {
  // Hacky way to get the offset from the request!
  const params = JSON.parse(response.config.data);
  if (response.data.error) {
    throw new Error(response.data.error?.message || response.data.error);
  }
  return {
    data: response.data.result.map((r) => ({
      ...r,
      id: r._id || r.id
    })),
    meta: {
      pagination: {
        total: 0,
        count: 0,
        per_page: PAGE_LIMIT,
        current_page: params.page || 1,
        total_pages: 0,
        links: []
      }
    }
  };
}

export function transformItem(response: any) {
  if (response.data.error) {
    throw new Error(response.data.error?.message || response.data.error);
  }
  return {
    ...response,
    data: response.data.result
  };
}

export function transformAutocomplete(response: any) {
  if (response.data.error) {
    throw new Error(response.data.error?.message || response.data.error);
  }
  return {
    data: response.data.result.map((r) => ({
      ...r,
      id: r._id || r.id
    }))
  };
}

/**
 * Maps the Wings response data from:
 * {
 *  id: 'value',
 *  text: 'Value'
 * }
 *
 * to:
 * {
 *  value: 'value',
 *  label: 'Value'
 * }
 *
 * as we will most likely use the value lists with a Select component which
 * expects this new format.
 *
 */
export function transformValueList(response: any) {
  if (response.data.error) {
    throw new Error(response.data.error?.message || response.data.error);
  }
  return {
    ...response,
    data: response.data.result.map((r) => ({
      value: r.id,
      label: r.text
    }))
  };
}

export function transformUpdateData({
  data,
  oldData
}: {
  data: any;
  oldData?: any;
}) {
  if (data.related) {
    const rel = {};
    Object.keys(data.related).forEach((relKey) => {
      const relData =
        _.get(data, `related.${relKey}.items`) ||
        _.get(data, `related.${relKey}`) ||
        [];
      const oldRelData =
        _.get(oldData, `related.${relKey}.items`) ||
        _.get(oldData, `related.${relKey}`) ||
        [];
      rel[relKey] = [
        ...relData.map((d) => ({ ...d, _destroy: 0 })),
        ...oldRelData
          .filter((d) => !relData.find((r) => r.id === d.id))
          .map((d) => ({ ...d, _destroy: 1 }))
      ];
    });
    return {
      ...data,
      related: rel
    };
  }
  return data;
}

export function transformListArgs({
  args
}: {
  args: {
    page?: number;
    limit?: number;
    [key: string]: any;
  };
}) {
  const { page, limit = PAGE_LIMIT, ...rest } = args;
  return {
    ...rest,
    limit,
    offset: page ? limit * (page - 1) : 0
  };
}

export function transformStreamArgs({
  args,
  lastItem
}: {
  args: any;
  lastItem?: any;
}) {
  return {
    ...args,
    limit: PAGE_LIMIT,
    last_post: lastItem
      ? {
          source_service: lastItem.source_service,
          id: lastItem.id,
          type: lastItem.type,
          timestamp: lastItem.timestamp
        }
      : null,
    page: args.page
  };
}

export function transformItemArgs({ id, args }: { id: string; args: any }) {
  return {
    id,
    ...args
  };
}

export function transformAutocompleteArgs({ args }: { args: any }) {
  return {
    ...args
  };
}

export function transformFormValuesToObjects(values: any) {
  if (!values) return null;

  return Object.keys(values).reduce<{ name: string; value: any }[]>(
    (result, currentValue) => {
      return [
        ...result,
        {
          name: currentValue,
          value: values[currentValue]
        }
      ];
    },
    []
  );
}
