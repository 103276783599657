import React, { PureComponent } from 'react';
import { StyleSheet, styled, StylesProvider } from '@rexlabs/styling';
import Checkbox from '@rexlabs/checkbox';
import Icon, { ICONS } from 'shared/components/icon';

import { COLORS } from 'shared/theme';

const overrides = {
  Icon: StyleSheet({
    icon: {
      transform: 'translate(-0.55rem, -0.65rem)',
      color: COLORS.PRIMARY.GREY_DARK
    }
  })
};

@styled(
  StyleSheet({
    unchecked: {
      display: 'none'
    }
  })
)
class RexCheckbox extends PureComponent {
  render() {
    const { styles: s, filled, ...props } = this.props;

    return (
      <StylesProvider components={overrides}>
        <Checkbox
          checkIcon={
            <Icon {...s({ unchecked: !this.props.value })} type={ICONS.TICK} />
          }
          {...props}
        />
      </StylesProvider>
    );
  }
}

export default RexCheckbox;
