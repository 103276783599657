import React, { Component } from 'react';
import Box from '@rexlabs/box';
import Body from 'shared/components/text/body';
import { PADDINGS } from 'src/theme';

// Shared
import AuthView from 'shared/components/auth-view';
import { ICONS } from 'shared/components/icon';

const {
  CHROME: ChromeIcon,
  EDGE: EdgeIcon,
  FIREFOX: FirefoxIcon,
  SAFARI: SafariIcon
} = ICONS;

class UnsupportedBrowser extends Component {
  render() {
    return (
      <Box style={{ textAlign: 'center' }}>
        <AuthView
          heading='This type of browser isn’t supported'
          body='Rex works best on modern browsers. To continue, please install one
            of the following recommended applications.'
        >
          <Box justifyContent='space-between' ml='4rem' mr='4rem'>
            <Box flexDirection='column' alignItems='center'>
              <Box mb={PADDINGS.XS}>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.apple.com/au/safari/'
                >
                  <SafariIcon />
                </a>
              </Box>
              <Body darkergrey normal semibold>
                Safari
              </Body>
            </Box>
            <Box flexDirection='column' alignItems='center'>
              <Box mb={PADDINGS.XS}>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.google.com/chrome/'
                >
                  <ChromeIcon />
                </a>
              </Box>
              <Body darkergrey normal semibold>
                Chrome
              </Body>
            </Box>
            <Box flexDirection='column' alignItems='center'>
              <Box mb={PADDINGS.XS}>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.mozilla.org/en-US/firefox/new/'
                >
                  <FirefoxIcon />
                </a>
              </Box>
              <Body darkergrey normal semibold>
                Firefox
              </Body>
            </Box>
            <Box flexDirection='column' alignItems='center'>
              <Box mb={PADDINGS.XS}>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.microsoft.com/en-au/windows/microsoft-edge'
                >
                  <EdgeIcon />
                </a>
              </Box>
              <Body darkergrey normal semibold>
                Edge
              </Body>
            </Box>
          </Box>
        </AuthView>
      </Box>
    );
  }
}

export default UnsupportedBrowser;
