import React from 'react';
import Text from '@rexlabs/text';
import { map } from 'shared/utils/text';

export default ({
  normal,

  white,
  small,
  large,
  regular,
  medium,
  semibold,

  bold,

  grey,
  darkgrey,
  darkergrey,
  red,
  ...props
}) => (
  <Text
    is='p'
    {...props}
    type={map({
      BODY: true,
      REGULAR: regular,
      MEDIUM: medium,
      SEMIBOLD: semibold,
      BOLD: bold,
      WHITE: white,
      SMALL: small,
      LARGE: large,
      NORMAL: normal,
      GREY: grey,
      DARK_GREY: darkgrey,
      DARKER_GREY: darkergrey,
      RED: red
    })}
  />
);
