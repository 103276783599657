import React from 'react';
import Text from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface BodyProps {
  normal?: boolean;
  light?: boolean;
  white?: boolean;
  small?: boolean;
  large?: boolean;
  regular?: boolean;
  medium?: boolean;
  semibold?: boolean;
  dark?: boolean;
  bold?: boolean;
  slate?: boolean;
  slateDark?: boolean;
  grey?: boolean;
  darkgrey?: boolean;
  darkergrey?: boolean;
  red?: boolean;
  green?: boolean;
  low?: boolean;
  children: string;
}

export default ({
  normal,
  light,
  white,
  small,
  large,
  regular,
  medium,
  semibold,
  dark,
  bold,
  slate,
  slateDark,
  grey,
  darkgrey,
  darkergrey,
  red,
  green,
  low,
  ...props
}: BodyProps) => {
  return (
    <Text
      is='p'
      {...props}
      type={map({
        BODY: true,
        BODY_DARK: dark,
        REGULAR: regular,
        MEDIUM: medium,
        SEMIBOLD: semibold,
        BOLD: bold,
        WHITE: white,
        SMALL: small,
        LARGE: large,
        NORMAL: normal,
        GREY: grey,
        DARK_GREY: darkgrey,
        DARKER_GREY: darkergrey,
        RED: red,
        GREEN: green,
        SLATE: slate,
        SLATE_DARK: slateDark,
        LOW: low
      })}
    />
  );
};
