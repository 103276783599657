/*
|-------------------------------------------------------------------------------
| App "Theme" for Internal Components
|-------------------------------------------------------------------------------
|
| These theming configurations are suppose to be consumed by `element-style`s
| "StylesProvider". See https://git.io/vNyT4 for more details.
|
| They are kept in a separate module, so we can do fine-grained import-order for
| the App as well as for Storybook Stories.
|
*/

import { StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS, TEXTS } from 'theme';
import { memoize } from 'lodash';

const ELEMENT_STYLE_COMPONENTS = memoize((appId) => ({
  InnerField: StyleSheet({
    field: {
      position: 'relative',
      marginTop: PADDINGS.S,

      '&:first-child': {
        marginTop: 0
      }
    },

    fieldLabel: {
      paddingBottom: '0',
      marginBottom: PADDINGS.XS,
      ...TEXTS.FIELD.LABEL
    },

    error: {
      position: 'absolute',
      marginTop: PADDINGS['3XS'],
      ...TEXTS.FIELD.ERROR
    }
  }),

  TextInput: StyleSheet({
    input: {
      outline: 'none',
      boxShadow: 'none',
      ...TEXTS.INPUT.CONTENT,
      height: '25px',
      paddingLeft: '0.25rem',

      '::placeholder': {
        ...TEXTS.INPUT.PLACEHOLDER
      },

      ':disabled::placeholder': {
        ...TEXTS.INPUT.PLACEHOLDER
      }
    },

    container: {
      width: '100%',
      height: 'auto',
      border: `1px solid ${COLORS.GREY}`,
      borderRadius: '2px',
      padding: PADDINGS.XS,
      transition: 'box-shadow 150ms ease-out'
    },

    containerError: {
      borderColor: COLORS.PRIMARY.RED,

      ':hover': {
        borderColor: COLORS.PRIMARY.RED
      },

      ':focus-within': {
        borderColor: COLORS.PRIMARY.RED
      },

      ':active': {
        borderColor: COLORS.PRIMARY.RED
      }
    },

    containerValid: {
      borderColor: COLORS.GREY
    },

    containerFocused: {
      outline: 'none',
      boxShadow: `0px 0px 1px 3px ${
        appId === 'pocket'
          ? COLORS.LOGIN.POCKET_BLUE
          : COLORS.LOGIN.BUTTON_FOCUS
      }`,
      borderColor: appId === 'pocket' ? COLORS.LOGIN.POCKET_BLUE : COLORS.BLUE
    },

    charLimitContainer: {},

    disabled: {
      backgroundColor: COLORS.SAND,

      '&:hover': {
        borderColor: COLORS.LOGIN.STATES.IDLE
      },

      '&:active': {
        borderColor: COLORS.LOGIN.STATES.ACTIVE
      }
    },

    prefix: {},

    suffix: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }),

  Checkbox: StyleSheet({
    input: {
      '& + label': {
        backgroundColor: COLORS.WHITE,
        cursor: 'default',
        borderRadius: '0.2rem',
        height: '1.7rem',
        width: '1.7rem',
        borderWidth: '0.2rem',
        borderColor: COLORS.LOGIN.STATES.IDLE,
        transition: 'none',
        marginLeft: '0',
        '&:before': {
          transition: 'none'
        },
        '&:focus': {
          outline: 'none'
        },
        '&:hover': {
          borderColor: COLORS.LOGIN.STATES.HOVER
        },
        '&:active': {
          borderColor: COLORS.LOGIN.STATES.ACTIVE
        }
      },
      '&:checked + label': {
        backgroundColor: COLORS.WHITE,
        transition: 'none',
        '&:before': {
          transition: 'none',
          display: 'none'
        }
      }
    },

    label: {
      ...TEXTS.FIELD.LABEL_DARK,
      cursor: 'default'
    }
  }),

  SelectInput: StyleSheet({
    isMenuOpen: {
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px'
    }
  }),

  SelectValue: StyleSheet({
    container: {
      ...TEXTS.INPUT.CONTENT
    }
  }),

  SelectMenu: StyleSheet({
    container: {
      padding: PADDINGS.XS,
      border: 'none',
      borderRadius: '2px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      boxShadow: `0px 10px 20px 0px ${COLORS.BLACK_15_OPACITY}`
    }
  }),

  SelectOption: StyleSheet({
    container: {
      ...TEXTS.INPUT.SELECT_OPTION,
      padding: PADDINGS.XS,
      paddingLeft: PADDINGS.XXS
    },
    isActive: {
      color: COLORS.DARK_GREY,
      background: COLORS.WHITE
    },
    isPassive: {
      background: COLORS.WHITE
    },
    isSelected: {
      color: COLORS.DARK_GREY,
      background: COLORS.WHITE
    }
  }),

  DefaultButton: StyleSheet({
    label: {
      fontSize: '16px'
    }
  }),

  Tooltip: StyleSheet({
    tooltip: {
      backgroundColor: COLORS.DARK_GREY,
      color: COLORS.WHITE,
      borderRadius: '0.5rem',
      padding: '20px',
      width: 285
    }
  }),

  Arrow: StyleSheet({
    arrow: {
      width: '40px',
      height: '20px',

      '&:before': {
        content: '" "',
        width: '10px',
        height: '10px',
        background: COLORS.DARK_GREY
      }
    }
  })
}));

export default ELEMENT_STYLE_COMPONENTS;
