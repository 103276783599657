import React, { Component } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';

// Shared
import UnsupportedBrowser from 'shared/components/unsupported-browser';

import { getIsValidBrowser } from 'shared/utils/browser';
import { PADDINGS } from 'shared/theme';
import Spinner from 'shared/components/spinner';

const defaultStyles = StyleSheet({
  container: {
    height: '100vh',
    width: '100vw'
  },

  main: {
    position: 'relative',
    width: '48rem',
    maxWidth: '90%',
    borderRadius: '0.5rem',
    padding: '4rem'
  },

  loadingMessage: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#414141',
    marginRight: '50px'
  }
});

@styled(defaultStyles)
class WrapperLayout extends Component {
  constructor() {
    super();
    this.isValidBrowser = getIsValidBrowser();
  }

  render() {
    const { styles: s, loadingMessage, children } = this.props;

    if (this.isValidBrowser && !loadingMessage) {
      return children;
    }

    return (
      <Box
        {...s('container')}
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <Box {...s('main')} flexDirection='column'>
          {loadingMessage ? (
            <Box
              alignItems='center'
              justifyContent='center'
              height='28rem'
              width='100%'
              p={PADDINGS.M}
            >
              <span {...s('loadingMessage')}>{loadingMessage}</span>
              <Spinner large dark />
            </Box>
          ) : (
            <UnsupportedBrowser />
          )}
        </Box>
      </Box>
    );
  }
}

export default WrapperLayout;
