// Todo: temporarily disabled browser detection due to problems for clients in production
// (that are most likely on unsupported versions ;D)
// import bowser from 'bowser';
import isWebView from 'is-webview';

// export function getIsValidBrowser(options) {
export function getIsValidBrowser() {
  // const browser = bowser.getParser(window.navigator.userAgent);

  if (
    isWebView(window.navigator.userAgent) ||
    window.navigator.userAgent.includes('wv')
  ) {
    return true;
  }

  // return browser.satisfies(
  //   options || {
  //     'microsoft edge': '>12', // This is what edge expects for it's name
  //     edge: '>12', // Keeping this just in case, remove if it never worked
  //     chrome: '>60',
  //     firefox: '>52',
  //     safari: '>10'
  //   }
  // );

  return true;
}
