// Copied from Spoke

import { defaultFilter } from '@rexlabs/select-input';
// import { LoadingSpinner } from 'view/components/loading';

import _ from 'lodash';

export const selectFilter = (options, props) => {
  const filteredOptions = defaultFilter(options, props).filter(
    (opt) => !opt.isFixture
  );
  const fixtureOptions = options.filter((opt) => Boolean(opt.isFixture));
  return filteredOptions.concat(fixtureOptions);
};

export const pluckValue = (option) => _.get(option, 'value', null);

export const filterOptionsByValue = (options, value, valueAsObject) => {
  const filteredOptions = options.filter((option) => {
    if (_.isArray(value)) {
      // Multiselect
      if (valueAsObject) {
        return value.find((v) => {
          // Compare value but also model name if available!
          // This is imporant, because entity models with multiple models
          // can potentially have duplicates of ids (=values) across them
          if (_.get(v, 'model')) {
            return (
              _.get(v, 'value') === _.get(option, 'value') &&
              _.get(v, 'model.modelName') === _.get(option, 'model.modelName')
            );
          }
          return v === _.get(option, 'value');
        });
      } else {
        return value.find((v) => v === _.get(option, 'value'));
      }
    } else {
      // Single select
      if (valueAsObject) {
        return _.get(value, 'value') === _.get(option, 'value');
      }
      return value === _.get(option, 'value');
    }
  });
  return filteredOptions;
};

// TODO: add loading indicator when the icon is added to sketch files
