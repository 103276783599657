import React from 'react';
import Text from '@rexlabs/text';
import { map } from 'shared/utils/text';

type Props = {
  children: string | string[] | null;
  level?: number;
};

export default ({ level, ...props }: Props) => {
  return (
    <Text
      is='h1'
      {...props}
      type={map({
        HEADING: true,
        HEADING_1: level === 1,
        HEADING_2: level === 2,
        HEADING_3: level === 3,
        HEADING_4: level === 4
      })}
    />
  );
};
