/**
 * Source: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
 * Minor deviations for ES6 support and to make the initialisation promise based
 */
export default function (apiKey, options) {
  return new Promise((resolve) => {
    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize) {
      if (analytics.invoked) {
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.');
      } else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware'
        ];
        analytics.factory = function (t) {
          return function (...args) {
            const e = Array.prototype.slice.call(args);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (let t = 0; t < analytics.methods.length; t++) {
          const e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t, e) {
          const n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = !0;
          n.src =
            'https://cdn.segment.com/analytics.js/v1/' +
            t +
            '/analytics.min.js';
          const a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = e;
        };
        analytics._writeKey = apiKey;
        analytics.SNIPPET_VERSION = '4.13.2';
        analytics.load(apiKey, options);
      }

      analytics.ready(() => {
        // We want to hide zendesk chat as quick as possible, to then selectively
        // enable it for specific accounts in the session model
        window.$zopim &&
          window.$zopim.livechat &&
          window.$zopim.livechat.hideAll();
        resolve();
      });
    } else {
      resolve();
    }
  });
}
