import React from 'react';
import { EntitySelect } from '@rexlabs/select';
// TODO - these look wrong and also are in shell
import Value from './defaults/value';
import Option from './defaults/option';
import Loading from './defaults/loading';
import NoResultsFound from './defaults/not-found';
import { api } from 'shared/utils/api-client';

async function autocomplete(searchTerm) {
  const { data } = await api.post('SystemValues::AutocompleteCategoryValues', {
    search_string: searchTerm,
    list_name: 'account_users'
  });
  return (data.result || []).map((user) => ({
    value: user?.id,
    label: user?.text
  }));
}

function UserSelect(props) {
  return (
    <EntitySelect
      {...props}
      autocomplete={autocomplete}
      Value={Value}
      Option={Option}
      Loading={Loading}
      NoResultsFound={NoResultsFound}
    />
  );
}

export { UserSelect };
