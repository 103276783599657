/*
|-------------------------------------------------------------------------------
| Singleton App Store (Redux)
|-------------------------------------------------------------------------------
|
| It's inclusion in other modules in the App should ideally come from dependency
| injection techniques to keep those files testable.
|
*/

import { combineReducers } from 'redux';
import { persistStore, persistReducer, createTransform } from 'redux-persist';

import { configureStore } from 'shared/utils/redux';
import {
  whereaboutsReducer as whereabouts,
  whereaboutsMiddleware
} from '@rexlabs/whereabouts';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';
import config from 'shared/utils/config';

import auth from 'shared/data/models/custom/auth';
import connection, {
  middleware as connectionMiddleware
} from 'shared/data/models/custom/connection';

import _ from 'lodash';

const isPocket =
  new URLSearchParams(location.search).get('app_id') === 'pocket';

const day = 86400;

/*
 | Persisted models
 |------------------------
 */
const persistAuth = persistReducer(
  {
    key: 'rex.auth',
    storage: new CookieStorage(Cookies, {
      setCookieOptions: { domain: config.COOKIE_URL },
      expiration: {
        // Retain email for 1 year for pocket, 1 month otherwise.
        // Note below that the token is deliberately omitted.
        // Note also that when you use the logout option in rex-app,
        // you will clear this storage regardless of cookie length.
        default: isPocket ? 365 * day : 30 * day
      }
    }),
    transforms: [
      createTransform(
        (state) => state,
        (state, key) => {
          if (key === 'lastAccount') {
            return state;
          }
          if (key === 'meta') {
            return _.omit(state, ['loading']);
          }
          if (key === 'loginInfo') {
            return _.omit(state, ['accounts', 'token']);
          }
          return state;
        }
      )
    ]
  },
  auth
);

/*
| Setup for Stores
|------------------------
*/
const reducers = combineReducers({
  auth: persistAuth,
  whereabouts,
  connection
});

const store = configureStore(reducers, [
  whereaboutsMiddleware,
  connectionMiddleware
]);

const persistedStore = persistStore(store, null, () => {
  store.dispatch(auth.actionCreators.init(true));
});

if (__DEV__) {
  window.purgePersistedStore = persistedStore.purge;
}

export default store;
