import ModelGenerator, {
  APIConfig,
  EntityModelConfig,
  ModelGeneratorOptions,
  ValueListModel
} from '@rexlabs/model-generator';
import { merge, snakeCase, upperFirst } from 'lodash';

import {
  api,
  transformAutocomplete,
  transformAutocompleteArgs,
  transformItem,
  transformItemArgs,
  transformList,
  transformListArgs,
  transformValueList
} from 'shared/utils/api-client';
import { transformListIds } from './api-client/transform';
import { ComponentType } from 'react';

interface SelectItem {
  value: string;
  label: string;
  data?: any;
  model?: any;
}

interface SelectConfig<RecordType> {
  autocomplete?: (searchTerm: string, extraData: any) => Promise<any[]>;
  Value?: ComponentType<any>;
  Option?: ComponentType<any>;
  Fixture?: ComponentType<any>;
  resultsToSelectOptions?: (results: RecordType[]) => SelectItem[];
  getPopoutData?: (id: string) => Promise<any>;
}

type FetchSystemList = (
  type?: string
) => Promise<ReturnType<typeof transformValueList>>;

export type SystemListModel<Type, Actions> = ValueListModel<
  Type & SelectItem,
  Actions & { fetch: FetchSystemList }
> & { select?: Record<string, any> };

export type GeneralSystemListModel = SystemListModel<
  SelectItem,
  Record<string, (...args: any) => any>
>;

export function resetEntities(entities) {
  return Object.keys(entities).reduce((acc, key) => {
    acc[key] = { lists: {}, items: {} };
    return acc;
  }, {});
}

export function resetValueLists(valueLists) {
  return Object.keys(valueLists).reduce((acc, key) => {
    acc[key] = { items: [], status: null, errors: null };
    return acc;
  }, {});
}

const defaultConfig: ModelGeneratorOptions = {
  entities: {
    api: {
      fetchList: (type, args: any) => {
        const { method = 'search', ...rest } = args;
        return api
          .post(
            `${upperFirst(type)}::${method}`,
            method === 'search'
              ? transformListArgs({ args: rest })
              : transformAutocompleteArgs({ args: rest })
          )
          .then(
            method === 'search'
              ? args.result_format === 'ids'
                ? transformListIds
                : transformList
              : transformAutocomplete
          );
      },

      fetchItem: (type, args, id) =>
        api
          .post(`${upperFirst(type)}::read`, transformItemArgs({ id, args }))
          .then(transformItem),

      updateItem: (type, args, id) =>
        api
          .post(`${upperFirst(type)}::update`, { data: { ...args, id: id } })
          .then(transformItem),

      createItem: (type, args) =>
        api.post(`${upperFirst(type)}::create`, {
          data: { ...args }
        }),

      trashItem: (type, args, id) =>
        api
          .post(`${upperFirst(type)}::trash`, { ...args, id: id })
          .then((response) => response.data.result)
    }
  },

  valueLists: {
    api: {
      fetch: (type) =>
        api
          .post('AdminValueLists::getListValues', {
            list_name: snakeCase(type)
          })
          .then(transformValueList)
    }
  }
};

type PartialEntityModelConfig = Partial<Omit<EntityModelConfig, 'api'>> & {
  api?: Partial<APIConfig>;
};

export type GeneratorConfig = {
  entities?: PartialEntityModelConfig;
  valueLists?: PartialEntityModelConfig;
};

export class Generator<T, A = any> extends ModelGenerator<T, A> {
  public serviceName: string;

  constructor(
    type: string,
    config: GeneratorConfig & { serviceName?: string } = {}
  ) {
    super(type, merge({}, defaultConfig, config));
    this.serviceName = config?.serviceName || upperFirst(type);
  }

  createSystemListModel(): SystemListModel<SelectItem, A> {
    return this.createValueListModel({
      config: {
        api: {
          fetch: (type) =>
            api
              .post('SystemValues::getCategoryValues', {
                list_name: snakeCase(type)
              })
              .then(transformValueList)
        }
      }
    });
  }

  // eslint-disable-next-line
  // @ts-ignore
  createEntityModel(args?: any) {
    // eslint-disable-next-line
    // @ts-ignore
    const model = super.createEntityModel(args);

    // @ts-ignore
    model.serviceName = this.serviceName;

    return model as typeof model & { select?: SelectConfig<T> };
  }
}
