export interface LoginMetadataCookie {
  first_name: string;
  last_name: string;
  email: string;
  last_login_at: string;
  last_login_app_id?: string | null;
  last_login_account_id?: string | null;
  login_remember_duration_minutes: number;
}

export function getAllCookies(): Record<
  string,
  string | Record<string, unknown>
> {
  return document.cookie
    .split(';')
    .map((c) => c.trim())
    .reduce(
      (accum: Record<string, string | Record<string, unknown>>, rawCookie) => {
        const [name, value] = rawCookie.split('=');
        accum[name] = maybeParseJson(decodeURIComponent(value));
        return accum;
      },
      {}
    );
}

function maybeParseJson(str: string): Record<string, unknown> | string {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export function getCookie<CookieType>(name: string) {
  if (!document.cookie) {
    return null;
  }

  return (getAllCookies()[name] as CookieType) || null;
}
