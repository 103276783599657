// Figure out release stage based on hostname, can probably swap this to sourcing from env.js file..
import _ from 'lodash';

export function getReleaseStageFromHostname(hostname) {
  const releaseStages = ['alpha', 'beta', 'demo', 'dev', 'app', 'auth'];
  const releaseStageRegExp = new RegExp(releaseStages.join('|'));
  let releaseStage = window.__DEV__ && 'development';

  if (!releaseStage) {
    releaseStage = _.first(hostname.split('.', 1)[0].match(releaseStageRegExp));
    if (releaseStage === 'app' || releaseStage === 'auth') {
      releaseStage = 'production';
    } else if (!releaseStage) {
      releaseStage = 'local';
    }
  }
  return releaseStage;
}
