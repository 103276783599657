import React, { PureComponent } from 'react';
import { ICONS } from 'shared/components/icon';
import { COLORS } from 'shared/theme';

const { EYE: Eye, EYE_CROSSED: EyeCrossed } = ICONS;

const iconStyles = {
  cursor: 'pointer',
  height: '2rem',
  width: '2rem',
  color: COLORS.GREY
};

class Toggle extends PureComponent {
  render() {
    const { showPassword, onClick } = this.props;
    return (
      <span onClick={onClick}>
        {showPassword ? (
          <Eye style={iconStyles} />
        ) : (
          <EyeCrossed style={iconStyles} />
        )}
      </span>
    );
  }
}
export default Toggle;
