import { get } from 'lodash';

export default (config) => {
  if (window.bugsnag && get(config, 'SHELL_BUGSNAG_ID')) {
    try {
      window.bugsnagClient = window.bugsnag({
        beforeSend: (report) => {
          if (
            [
              'ResizeObserver loop limit exceeded',
              'ResizeObserver loop completed with undelivered notifications.'
            ].includes(report.errorMessage)
          ) {
            report.ignore();
            return;
          }

          // We're using the shell bugsnag client in classic (to get session data + FS link
          // for free), so we need to set the appropriate API key based on the error we're getting
          // We set the error attribute identifying classic in the bridge setup
          // See: shell/src/data/classic-bridges/index.js
          report.apiKey = get(report, 'originalError.isClassic')
            ? config.CLASSIC_BUGSNAG_ID
            : config.SHELL_BUGSNAG_ID;

          if (!report.apiKey) {
            report.ignore();
          }
        },
        // NOTE: we're setting the API key to shell here, but we're changing it dyanmically
        // in the `beforeSend` callback above to distinguish between classic and shell
        apiKey: get(config, 'SHELL_BUGSNAG_ID'),
        appVersion: get(config, 'RELEASE.VERSION'),
        releaseStage: get(config, 'RELEASE.STAGE') || 'local'

        // TODO: Audit breadcrumbs for what is just noise and can be ignored
        // autoBreadcrumbs: false,
        // consoleBreadcrumbsEnabled: false,
        // interactionBreadcrumbsEnabled: false,
        // navigationBreadcrumbsEnabled: true, // Probably only one we will keep imo
        // networkBreadcrumbsEnabled: false
      });
    } catch (e) {
      console.error(`Bugsnag configuration variables are invalid:\n${e}`);
    }
  }
};
