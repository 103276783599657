import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT } from 'shared/theme';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { autobind } from 'core-decorators';
import { withWhereabouts } from '@rexlabs/whereabouts';
import config from 'shared/utils/config';

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: FONT.FAMILY.PROXIMA_NOVA,
    width: '100%',
    height: '100%'
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },

  heading: {
    color: COLORS.DARKER_GREY,
    fontWeight: 700,
    marginTop: '20px',
    fontSize: '26px',
    lineHeight: 1.2,
    paddingBottom: '19px'
  },

  paragraph: {
    width: '100%',
    color: COLORS.DARKER_GREY,
    fontSize: '16px',
    lineHeight: '22px',
    paddingBottom: '26px',
    textAlign: 'left',

    '& a': {
      color: 'inherit',
      fontWeight: 600,
      fontSize: '16px',
      textDecoration: 'underline',

      '&:hover': {
        opacity: '0.7'
      }
    }
  },

  button: {
    width: '100%',
    maxWidth: '400px'
  },

  illustration: {
    marginBottom: '40px'
  }
});

@withWhereabouts
@styled(defaultStyles)
@autobind
class MaintenanceScreen extends PureComponent {
  getFinishDate() {
    const {
      match: { query }
    } = this.props;

    // Plus symbol appears to be converted to a space when grabbed from the URL
    const date = (_.get(query, 'date') || '').replace(' ', '+');
    const hasDate = !!date;
    const isFutureDate = hasDate ? dayjs(date).isAfter(dayjs()) : null;
    const formattedDate = hasDate
      ? dayjs(date).format('dddd Do MMMM, HH:mma [GMT]Z')
      : null;

    return hasDate && isFutureDate
      ? `on ${formattedDate} (${dayjs().to(dayjs(date))})`
      : 'soon';
  }

  componentDidMount() {
    window.location.href = config.REX_APP_URL;
  }

  render() {
    const {
      styles: s,
      match: { query }
    } = this.props;
    const isPocket = _.get(query, 'app_id') === 'pocket';
    const appName = isPocket ? 'Pocket' : 'Rex CRM';

    return (
      <Box {...s('container')}>
        <Box {...s('wrapper')}>
          <h1 {...s('heading')}>
            {appName} is experiencing a service interruption due to an issue
            with our infrastructure provider (Google Cloud Platform)
          </h1>
          <p {...s('paragraph', 'paddingTop')}>
            Our engineering team is urgently working with Google and we will
            provide an update as soon as possible.
          </p>
          <p {...s('paragraph', 'paddingTop')}>
            <p {...s('paragraph', 'paddingTop')}>
              <span style={{ fontWeight: 'bold' }}>Update – 2:50PM AEST:</span>{' '}
              Google has advised that their services are still recovering, and
              they will provide another update by approximately 4:30PM AEST. We
              are continuing to closely monitor the situation.
            </p>
            <span style={{ fontWeight: 'bold' }}>Update – 1:45PM AEST:</span>{' '}
            Google has confirmed{' '}
            <span style={{ fontWeight: 'bold' }}>
              a power outage in one of their data centers
            </span>{' '}
            that has impacted many services. No data is being reported as being
            lost (and we have multi region backups even if that was the case).
            They have reported that they have a mitigation strategy and that
            some services are now recovering but have not provided an ETA.
          </p>
        </Box>
      </Box>
    );
  }
}

export default MaintenanceScreen;
